import React from "react";
import PropTypes from "prop-types";
import { useDetail, useQuery } from "seed/gql";
import { useLocation } from 'react-router-dom';
import NotFound from "components/utils/NotFound";
import { Loading } from "seed/helpers"
import View from "components/partner/Main.view";

function Main() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const id = query.get('id');
  const reqReview = useDetail(`{
    review {
      token
      deadline
      dateReviewed
      isDelayed
      isActive
      isReviewed
      document {
        data
        status
        request {
          ndDocumentId
        }
        file { url }
        request {  }
      }
    }
  }`, id);

  if (reqReview.loading) return <Loading />;
  if (reqReview.error) return <NotFound />;

  const { review = {} } = reqReview.data;

  if (review.token != token || review.isActive == false) 
    window.location.href = "/";

  if (review.isReviewed == true) 
    return <View review={review} reviewed={true}/>

  return <View review={review}/>;
}

Main.propTypes = {};

export default Main;
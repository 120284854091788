import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { PaginationFooter } from "seed/helpers"

const RequestList = ({ requests, pageNum = 1, totalPages = 0, onClickPage = () => { }, lastApprovals }) =>
  <div>
    <li class="list-group-item">
      <div class="row align-items-center gx-2">

        <div class="col-md-1">
          REQUEST
        </div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-2">
              FECHA
            </div>
            <div class="col-md-2">
              STATUS
            </div>
            <div class="col-md-2">
              DOCUMENT ID
            </div>
            <div class="col-md-2">
              SOCIO
            </div>
            <div class="col-md-2">
              ASOCIADO
            </div>
          </div>
        </div>

        <div class="col-md-1">
          VER METADATA
        </div>
      </div>
    </li>
    <ul class="list-group">
      {
        requests.map((request) =>
          <li key={request.id} class="list-group-item">
            <div class="row align-items-center gx-2">
              <div class="col-md-1">
                <h5 class="mb-0">
                  <Link to={`/${request.id}`}>REQUEST {request.id}</Link>
                </h5>
              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-2">
                    {
                      lastApprovals[request.id]["date"]
                    }
                  </div>
                  <div class="col-md-2">
                    {request.status}
                  </div>
                  <div class="col-md-2">
                    {request.ndDocumentId}
                  </div>
                  <div class="col-md-2">
                    {
                      lastApprovals[request.id]["partner"]
                    }
                  </div>
                  <div class="col-md-2">
                    {
                      lastApprovals[request.id]["associate"]
                    }
                  </div>
                </div>
              </div>

              <div class="col-md-1">
                {/* Options */}
                <Link to={`/${request.id}`} className="btn btn-sm btn-white">
                  <div class="mr-1">Metadata</div>
                </Link>
              </div>
            </div>
          </li>
        )
      }
    </ul>

    <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />

  </div>;

RequestList.propTypes = {
  requests: PropTypes.array.isRequired,
  pageNum: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPage: PropTypes.func,
  lastApprovals: PropTypes.object
};

export default RequestList;
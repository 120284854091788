import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import swal from "sweetalert";
import "moment/locale/es-mx";
import { usePost } from "seed/api";
import { useQuery, useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/cc/reviewer/Reviewer.view";

function ReviewerEdit({ onCompleted = () => null, onError = () => null, documentId, reqDocuments }) {

  const [error, setError] = useState(null);
  const [startReview, setStartReview] = useState(false);

  const reqPartners = useQuery(`{
    users {
      firstName
      lastName
      email
    }
  }`, "status='PARTNER'", {
    onError: () => setError("Ha ocurrido un error"),
    orderBy: "first_name"
  });

  const reqDocument = useDetail(`{
    document {
      status
      createdAt
      reviews { 
        deadline
        reviewer {
          firstName
          lastName
          email
        }
      }
    }
  }`, documentId);

  const [callSave, qSave] = usePost("/documents/start_review", {
    onCompleted: () => {
      swal({
        title: startReview ? "Revisión iniciada exitosamente" : "Socio asignado exitosamente",
        icon: "success",
        text: startReview ? "La revisión del documento ha sido iniciada, se le notificará al socio encargado"
          : "El socio ha sido asignado exitosamente para la revisión del documento",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/cc_home";
        }
      });
    },
    onError: (data) => {
      if (data.status == 401) {
        setError("Hubo un error, el documento ya tiene socio asignado");
      } else {
        setError("Error interno del servidor, por favor intente mas tarde");
      }
    }
  });

  if (reqPartners.loading || reqDocument.loading) return <Loading />;
  if (reqPartners.error) return "Error";

  const { users = [] } = reqPartners.data;
  const { document } = reqDocument.data;
  const initialReviewers = document.reviews.map(review => review.reviewer);

  if (!document) window.location.href = "/cc_home";

  if (document.status != "ASSIGNED") window.location.href = "/cc_home";
  const initialValues = {
    reviewers: document.reviews.map(review => review.reviewer.id),
    deadline: moment(document.reviews[0].deadline).format("YYYY-MM-DD")
  }

  const onSubmit = (values, start) => {
    const deadline = values["deadline"];
    const reviewers = values?.reviewers ?? [];
    setStartReview(start);

    if (!deadline || reviewers.length == 0) {
      setError("Debe rellenar todos los campos")
      return;
    }

    const now = moment();
    const timeDeadline = moment(deadline);

    if (now > timeDeadline) {
      setError("La fecha limite debe ser mayor que la fecha actual");
      return;
    }

    if (start) {

      swal({
        title: "Iniciar revisión",
        icon: "warning",
        text: "¿Desea iniciar la revisión?",
        buttons: {
          confirm: {
            text: "Aceptar",
            className: "swal-button swal-button--cancel btn-success",
          },
          cancel: "Cancelar",
        },
      }).then((respuesta) => {
        if (respuesta)
          callSave({
            documentId: documentId,
            deadline: deadline,
            reviewers: reviewers,
            start: start
          })
      });

    } else if (!start) {
      callSave({
        documentId: documentId,
        deadline: deadline,
        reviewers: reviewers,
        start: start
      })
    }


  }

  return (
    <View
      partners={users}
      onSubmit={onSubmit}
      error={error}
      initialReviewers={initialReviewers}
      document={initialValues}
      edit={true}
    />
  );
}

ReviewerEdit.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  documentId: PropTypes.string,
  reqDocuments: PropTypes.object,
  props: PropTypes.object,
  match: PropTypes.object
};

export default ReviewerEdit;
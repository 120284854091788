import React, { useState } from "react";
import PropTypes from "prop-types";
import { FORM_SCHEMA } from "utils";
import { useDetail, useQuery, useSave } from "seed/gql";
import { APPROVAL, SET_APPROVAL, USER } from "seed/gql/queries";
import { IS_PROD } from "settings";
import View from "components/form/QaApproval.view";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import { useGet, usePost } from "seed/api";
import { useLocation } from "react-router";
import NotFound from "components/utils/NotFound";

function QaApproval() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [callLoading, setCallLoading] = useState(false);
  const formId = localStorage.getItem("formId");

  const userId = query.get("userId");
  const reqUser = useGet("/users/" + userId);
  const reqDocument = useGet(
    "/approvals/" + formId + "/get_document_name_id"
  );
  const [queryPermS, setQueryPermS] = useState({ value: "", hidden: false });
  const [formatSignS, setFormatSignS] = useState({ value: "", hidden: false });
  const [isTranslationS, setIsTranslationS] = useState({
    value: "",
    hidden: false,
  });
  const [isComplementS, setIsComplementS] = useState({
    value: "",
    hidden: false,
  });
  const [isSubstitutionS, setIsSubstitutionS] = useState({
    value: "",
    hidden: false,
  });
  const [codFiscalFederationS, setCodFiscalFederationS] = useState({
    value: "",
  });
  const [docReportTypeS, setDocReportTypeS] = useState({
    value: "",
    hidden: false,
  });
  const [art5aS, setArt5aS] = useState({ value: "", hidden: false });
  const [art199S, setArt199S] = useState({ value: "", hidden: false });
  const [desingSchemeS, setDesingSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [clientReportS, setClientReportS] = useState({
    value: "",
    hidden: false,
  });
  const [art197S, setArt197S] = useState({ value: "", hidden: false });
  const [impetimentLegalS, setImpetimentLegalS] = useState({
    value: "",
    hidden: false,
  });
  const [art197ConstS, setArt197ConstS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveSchemaS, setRelieveSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [mountSchemaS, setMountSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [firstComercializationS, setFirstComercializationS] = useState({
    value: "",
    hidden: false,
  });
  const [art201S, setArt201S] = useState({ value: "", hidden: false });
  const [declarationInfoS, setDeclarationInfoS] = useState({
    value: "",
    hidden: false,
  });
  const [otherAsesorsS, setOtherAsesorsS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveAsesorS, setRelieveAsesorS] = useState({
    value: "",
    hidden: false,
  });
  const [reportableGeneralS, setReportableGeneralS] = useState({
    value: "",
    hidden: false,
  });
  const [art197pa6S, setArt197pa6S] = useState({ value: "", hidden: false });
  const [asesorArt197pa6S, setAsesorArt197pa6S] = useState({
    value: "",
    hidden: false,
  });
  const [constRelieveSchemeS, setConstRelieveSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [eptOrValuationS, setEptOrValuationS] = useState({ value: "", hidden: false })
  const [ccPrevS, setCcPrevS] = useState({ value: "", hidden: false })
  const [eptMov, setEptMov] = useState([{ dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }]);
  const [areas, setAreas] = useState([{ area: "" }]);

  const reqForm = useGet(
    "/approvals/" + formId,
    {},
    {
      onCompleted: (data) => {
        const { body } = data;
        const { emittingArea, eptMov } = body;
        setAreas(emittingArea);
        setEptMov(eptMov);
      }
    }
  );

  const onCompletedSet = () => {
    swal({
      title: "Información correcta",
      icon: "success",
      text: "Se aprobó el contenido",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then(() => {
      window.close();
      window.location.reload();
    });
  };

  const onCompletedRejected = () => {
    swal({
      title: "Contenido Rechazado",
      text: "Se envió el motivo de no aprobación",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then(() => {
      window.close();
      window.location.reload();
    });
  };

  const [callSet, reqSet] = usePost(
    "/approvals/" + formId + "/approval_request",
    {
      onCompleted: (data) => {
        setCallLoading(false);
        onCompletedSet();
      }
    }
  );

  const [callReject, reqReject] = usePost(
    "/approvals/" + formId + "/approval_request",
    {
      onCompleted: (data) => {
        setCallLoading(false);
        onCompletedRejected();
      }
    }
  );
  const onChangeArea = (index, event) => {
    const values = [...areas];
    values[index].area = event.target.value;
    setAreas(values);
  };
  const removeArea = (index) => {
    const values = [...areas];
    values.splice(-1, 1);
    setAreas(values);
  };
  const addArea = () => {
    setAreas([...areas, { area: "" }]);
  };
  const addEptMov = () => {
    setEptMov([...eptMov, { dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }])
  }
  const removeEptMov = (index) => {
    const values = [...eptMov];
    values.splice(-1, 1);
    setEptMov(values);
  }

  const onChangeEptMov = (index, event) => {
    const values = [...eptMov];
    values[index][event.target.name] = event.target.value;
    setEptMov(values);
  }

  if (reqForm.loading || reqUser.loading || callLoading || reqDocument.loading)
    return <Loading />;
  if (reqForm.error || reqUser.error) return "error";
  if (userId == "" || formId == "") return <NotFound />;
  const approval = reqForm.data;
  const user = reqUser.data;
  const { body, status, comments } = approval;
  const { documentId, name } = reqDocument.data;
  const formData = body;

  const onSubmit = (values) => {
    values.queryPerm =
      queryPermS.value == "" ? values.queryPerm : queryPermS.value;
    values.formatSignCheck =
      formatSignS.value == "" ? values.formatSignCheck : formatSignS.value;
    values.isTranslation =
      isTranslationS.value == "" ? values.isTranslation : isTranslationS.value;
    values.isComplement =
      isComplementS.value == "" ? values.isComplement : isComplementS.value;
    values.isSubstitution =
      isSubstitutionS.value == ""
        ? values.isSubstitution
        : isSubstitutionS.value;
    values.isReportable =
      codFiscalFederationS.value == ""
        ? values.isReportable
        : codFiscalFederationS.value;
    values.docReportType =
      docReportTypeS.value == "" ? values.docReportType : docReportTypeS.value;
    values.art199 = art199S.value == "" ? values.art199 : art199S.value;
    values.desingScheme =
      desingSchemeS.value == "" ? values.desingScheme : desingSchemeS.value;
    values.clientReport =
      clientReportS.value == "" ? values.clientReport : clientReportS.value;
    values.art197 = art197S.value == "" ? values.art197 : art197S.value;
    values.impetimentLegal =
      impetimentLegalS.value == ""
        ? values.impetimentLegal
        : impetimentLegalS.value;
    values.art197Const =
      art197ConstS.value == "" ? values.art197Const : art197ConstS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.mountSchema =
      mountSchemaS.value == "" ? values.mountSchema : mountSchemaS.value;
    values.firstComercialization =
      firstComercializationS.value == ""
        ? values.firstComercialization
        : firstComercializationS.value;
    values.art201 = art201S.value == "" ? values.art201 : art201S.value;
    values.declarationInfo =
      declarationInfoS.value == ""
        ? values.declarationInfo
        : declarationInfoS.value;
    values.otherAsesors =
      otherAsesorsS.value == "" ? values.otherAsesors : otherAsesorsS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.art197pa6 =
      art197pa6S.value == "" ? values.art197pa6 : art197pa6S.value;
    values.asesorArt197pa6 =
      asesorArt197pa6S.value == ""
        ? values.asesorArt197pa6
        : asesorArt197pa6S.value;
    values.relieveSchema =
      relieveSchemaS.value == "" ? values.relieveSchema : relieveSchemaS.value;
    values.constRelieveScheme =
      constRelieveSchemeS.value == ""
        ? values.constRelieveScheme
        : constRelieveSchemeS.value;
    values.emittingArea = areas;
    values.eptOrValuation = eptOrValuationS.value == "" ? values.eptOrValuation : eptOrValuationS.value;
    values.ccPrev = ccPrevS.value == "" ? values.ccPrev : ccPrevS.value;
    values.eptMov = eptMov;

    if (formData == values) {
      setCallLoading(true);
      callSet({
        body: values,
        status: "APPROVED",
        comments: "",
        userId: userId,
      });
    } else {
      setCallLoading(true);
      callSet({
        status: "APPROVER_CORRECTED",
        body: values,
        comments: "",
        userId: userId,
      });
    }
  };

  const onSubmitRejected = () => {
    swal({
      text: "Indica el motivo del rechazo",
      content: { element: "textarea" },
      dangerMode: true,
      buttons: {
        confirm: {
          text: "Enviar",
          closeModal: false,
          className: "swal-button swal-button--cancel btn-danger",
        },
        cancel: "Cancelar",
      },
    }).then((motive) => {
      if (!motive) throw null;
      motive = document.querySelector(".swal-content__textarea").value;
      callReject({
        body: formData,
        status: "REJECTED",
        comments: motive,
        userId: userId,
      });
      setCallLoading(true);
    });
  };

  const approval_comments = approval.comments.split(":");
  const isUserRejeted = user.email == approval_comments[1] ? true : false;

  let wasApproved = false;
  for (let associated of approval.associated)
    if (associated.approval == true && associated.email == user.email) wasApproved = true
  for (let partner of approval.partners)
    if (partner.approval == true && partner.email == user.email) wasApproved = true

  if (wasApproved)
    return (<main
      id="content"
      style={{ "padding-left": "2.25rem" }}
    >
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>

      <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="index.html">
          <h1
            class="mt-2 display-4"
            style={{
              position: "absolute",
              "z-index": "3",
              color: "white",
              "margin-right": "22.5rem",
            }}
          >
            Hoja Crono
          </h1>
          <img
            class="z-index-2"
            src="/theme/svg/logos/logoCrz.png"
            alt="Logo"
            style={{ width: "8rem" }}
          />
        </a>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="card card-lg mb-5">
            <div class="card-body text-center pt-8 pb-9">
              <h4>La hoja crono ya fue revisada</h4>
            </div>
          </div>
        </div>
      </div>
    </main>)

    return (
      <View
        documentId={documentId}
        name={name}
        formData={formData}
        user={user}
        onSubmitRejected={onSubmitRejected}
        onSubmit={onSubmit}
        queryPermS={queryPermS}
        formatSignS={formatSignS}
        setQueryPermS={setQueryPermS}
        setFormatSignS={setFormatSignS}
        FormSchema={FORM_SCHEMA}
        status={status}
        comments={approval_comments[0]}
        isUserRejeted={isUserRejeted}
        codFiscalFederationS={codFiscalFederationS}
        setCodFiscalFederationS={setCodFiscalFederationS}
        isTranslationS={isTranslationS}
        setIsTranslationS={setIsTranslationS}
        isComplementS={isComplementS}
        setIsComplementS={setIsComplementS}
        isSubstitutionS={isSubstitutionS}
        setIsSubstitutionS={setIsSubstitutionS}
        docReportTypeS={docReportTypeS}
        setDocReportTypeS={setDocReportTypeS}
        art199S={art199S}
        setArt199S={setArt199S}
        desingSchemeS={desingSchemeS}
        setDesingSchemeS={setDesingSchemeS}
        clientReportS={clientReportS}
        setClientReportS={setClientReportS}
        art197S={art197S}
        setArt197S={setArt197S}
        impetimentLegalS={impetimentLegalS}
        setImpetimentLegalS={setImpetimentLegalS}
        art197ConstS={art197ConstS}
        setArt197ConstS={setArt197ConstS}
        relieveSchemaS={relieveSchemaS}
        setRelieveSchemaS={setRelieveSchemaS}
        mountSchemaS={mountSchemaS}
        setMountSchemaS={setMountSchemaS}
        firstComercializationS={firstComercializationS}
        setFirstComercializationS={setFirstComercializationS}
        declarationInfoS={declarationInfoS}
        setDeclarationInfoS={setDeclarationInfoS}
        art201S={art201S}
        art5aS={art5aS}
        setArt5aS={setArt5aS}
        reportableGeneralS={reportableGeneralS}
        setReportableGeneralS={setReportableGeneralS}
        setArt201S={setArt201S}
        otherAsesorsS={otherAsesorsS}
        setOtherAsesorsS={setOtherAsesorsS}
        relieveAsesorS={relieveAsesorS}
        setRelieveAsesorS={setRelieveAsesorS}
        art197pa6S={art197pa6S}
        setArt197pa6S={setArt197pa6S}
        asesorArt197pa6S={asesorArt197pa6S}
        setAsesorArt197pa6S={setAsesorArt197pa6S}
        constRelieveSchemeS={constRelieveSchemeS}
        setConstRelieveSchemeS={setConstRelieveSchemeS}
        addArea={addArea}
        removeArea={removeArea}
        onChangeArea={onChangeArea}
        areas={areas}
        eptOrValuationS={eptOrValuationS}
        setEptOrValuationS={setEptOrValuationS}
        eptMov={eptMov}
        setEptMov={setEptMov}
        addEptMov={addEptMov}
        removeEptMov={removeEptMov}
        onChangeEptMov={onChangeEptMov}
        ccPrevS={ccPrevS}
        setCcPrevS={setCcPrevS}
        IS_PROD={IS_PROD}
      />
    );
  }

  QaApproval.propTypes = {};

  export default QaApproval;

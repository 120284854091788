import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es-mx";
import { Link } from "react-router-dom";
import { getStatusName, getStatusClass } from "components/utils/constants";
import { ModalRoute, PaginationFooter } from "seed/helpers";
import ReviewerSave from "components/cc/reviewer/ReviewerSave";
import QaRequest from "components/cc/QaRequestCC";
import ReviewerEdit from "components/cc/reviewer/ReviewerEdit";
import History from "components/cc/history/History";
import Correction from "components/cc/correction/Correction";
import Committee from "components/cc/correction/Committee";
import QaRequestEdit from "components/cc/QaRequestCCEdit";
import { getHelpMessage, getHelpComponent } from "components/utils/constants";
import HelpMessage from "components/helpers/HelpMessage";

function getButtonAction(document) {

  if (document.status == "SENT")
    return (
      <HelpMessage message={getHelpMessage("assign_document")}>
        <Link to={`/${document.id}/assign`} class="btn-sm btn-primary">Asignar</Link>
      </HelpMessage>
    );

  if (document.status == "ASSIGNED")
    return (
      <HelpMessage message={getHelpMessage("review_document")}>
        <Link to={`/${document.id}/edit`} class="btn-sm btn-primary">📧 Iniciar revisión</Link>
      </HelpMessage>
    );

  if (["IN_REVIEW", "PARTIALLY_REVIEWED", "REVIEWED", "REVIEWED_WITH_COMMENTS", "REVIEWD_WITH_COMMITTEE"].includes(document.status))
    return (
      <HelpMessage message={getHelpMessage("details_document")}>
        <Link to={`/${document.id}/history`} class="btn-sm btn-outline-primary">Detalles</Link>
      </HelpMessage>
    );

  if (document.status == "WITH_COMMENTS") {
    return (<>
      <Link to={`/${document.id}/correction`} class="btn-sm btn-outline-primary">Registrar corrección</Link>
      <Link to={`/${document.id}/history`} class="btn-sm btn-outline-primary">Detalles</Link>
    </>)
  }

  if (document.status == "NEEDS_COMMITTEE") {
    return (<>
      <Link to={`/${document.id}/committee`} class="btn-sm btn-outline-primary">Registrar comité</Link>
      <Link to={`/${document.id}/history`} class="btn-sm btn-outline-primary">Detalles</Link>
    </>)
  }

}

function getTitle(data) {
  return data?.title ?? "";
}

const DocumentListView = ({
  trash,
  documents = [],
  orderFolio,
  setOrderFolio,
  orderDocumentDate,
  setOrderDocumentDate,
  totalPages = 0,
  pageNum = 0,
  reqDocuments,
  onClickPage = () => { },
  onClickDelete = () => { },
  onClickResend = () => { },
  getLastReview = () => { },
  onRestoreDocument = () => { },
}) => (

  <>
    <div class="table-responsive">
      <table
        class="table table-borderless table-thead-bordered
        table-align-middle card-table"
      >

        <thead class="thead-light">
          <tr role="row">
            <th>
              <div className="d-flex flex-row align-items-center" style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                <span>Folio revisión</span>
                <div className="mx-1">
                  <button type="button" className={`btn btn-text mx-1 px-0`}
                    onClick={() => { setOrderFolio(""); setOrderDocumentDate(null) }}
                  >
                    <i className={`fa fa-arrow-up ${orderFolio == "" ? "text-success" : ""}`}></i>
                  </button>
                  <button type="button" className={`btn btn-text mx-1 px-0`}
                    onClick={() => { setOrderFolio("-"); setOrderDocumentDate(null) }}
                  >
                    <i className={`fa fa-arrow-down ${orderFolio == "-" ? "text-success" : ""}`}></i>
                  </button>
                </div>
              </div>
            </th>
            <th><span>Documento</span></th>
            <th><span>Hoja crono</span></th>
            <th><span>Revisión</span></th>
            <th><span>Acciones</span></th>
          </tr>
        </thead>

        <tbody>
          {documents.map((document) => (
            <tr class={`justify-content-center ${(document.trash && !trash) ? "document-row-disabled" : ""}`}
              role="row" key={`document_${document.id}`}>

              <td>
                {document.folio} <br />
              </td>

              <td style={{ maxWidth: "350px", overflow: "hidden" }}>
                <div class="">
                  <b>{getTitle(document.data)}</b>
                </div>
                {(!document.trash || trash) && (
                  <>
                    <div className="">
                      <span class="badge py-0 px-0">{document.client ?? ""}</span>
                    </div>
                    <div className="mt-0 pt-0">
                      <small style={{ fontSize: "0.8em" }}>
                        {document.documentDate ? "Fecha doc: " + moment(document.documentDate).format("DD-MMM-YYYY").replace(".", "") : ""}
                      </small>
                    </div>
                  </>
                )}
              </td>

              <td style={{ maxWidth: "350px", overflow: "hidden" }}>
                {(!document.trash || trash) && (
                  <>
                    <div class="">
                      <span class="badge py-0 px-0" style={{ fontSize: "0.85em" }}>ID HC: {document.request?.id ? document.request?.id : "N/A"}</span>
                    </div>

                    <div className="">
                      <small style={{ fontSize: "0.9em" }}>
                        Soc: {document.data?.partners && document.data?.partners.length > 0 ?
                          document.data?.partners.map(p => (p.email ?? "").split("@")[0]).join(", ") : ""}
                      </small>
                    </div>
                    <div className="mt-0 pt-0">
                      <small style={{ fontSize: "0.9em" }}>
                        Asc: {document.data?.associated && document.data?.associated.length > 0 ?
                          document.data?.associated.map(a => (a.email ?? "").split("@")[0]).join(", ") : ""}
                      </small>
                    </div>
                  </>
                )}
              </td>

              <td>
                <span class={(document.trash && !trash) ? "badge bg-danger text-white" : getStatusClass(document.status)}
                  style={document.status == "REVIEWED" ? { backgroundColor: "#c7f0c0" } : {}}>
                  {(document.trash && !trash) ? "Eliminado" : getStatusName(document.status)}
                </span>

                {!document.trash && (
                  <>
                    <div className="pt-1">
                      <span style={{ fontSize: "0.9em", fontWeight: "bold" }}>
                        {document.status == "SENT" ? "" : "(" + document.reviews.map(review => (review?.reviewer?.firstName + " " + review?.reviewer?.lastName ?? "").split("@")[0]).join(",  ") + ")"}
                      </span>
                    </div>

                    <div className="mt-0 pt-0">
                      <small style={{ fontSize: "0.9em" }}>
                        {(document.status == "ASSIGNED" || document.status == "IN_REVIEW" || document.status == "PARTIALLY_REVIEWED") && document.reviews[0].isDelayed ?
                          (<span class="ml-2 text-danger">(Retrasado)</span>) : null}
                      </small>
                    </div>
                  </>
                )}
              </td>

              <td>

                {trash && (
                  <>
                    <HelpMessage message={getHelpMessage("restore_document")}>
                      <a
                        class="btn-sm btn-soft-warning ml-2 mt-2"
                        target="_blank" rel="noreferrer"
                        onClick={() => onRestoreDocument(document.id)}
                      >
                        <i class="fas fa-trash-restore text-warning"></i>
                      </a>
                    </HelpMessage>
                    <HelpMessage message={getHelpMessage("view_document")}>
                      <a
                        class="btn-sm btn-soft-success ml-2 mt-2"
                        target="_blank" rel="noreferrer"
                        href={document.file ? document.file.url :
                          `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`}
                      >

                        <i class="fas fa-eye"></i>
                      </a>
                    </HelpMessage>
                  </>
                )}

                {!document.trash && (
                  <>
                    {getButtonAction(document, trash)}

                    {(document.status == "IN_REVIEW" || document.status == "PARTIALLY_REVIEWED") && (
                      <HelpMessage message={getHelpMessage("resend_document")}>
                        <a
                          class="btn-sm btn-soft-primary ml-2 mt-2"
                          onClick={() => onClickResend(document.id)}
                        >
                          Reenviar
                        </a>
                      </HelpMessage>
                    )}

                    <HelpMessage message={getHelpMessage("view_document")}>
                      <a
                        class="btn-sm btn-soft-success ml-2 mt-2"
                        target="_blank" rel="noreferrer"
                        href={document.file ? document.file.url :
                          `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`}
                      >

                        <i class="fas fa-eye"></i>
                      </a>
                    </HelpMessage>

                    <div class="btn-group dropdown-more-options-hc">
                      <button type="button" class="btn btn-sm btn-outline-primary more-options-hc ml-2 px-3 py-1"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <div class="dropdown-menu pb-0">

                        <a class="dropdown-item" onClick={() => onClickDelete(document.id)}>
                          <HelpMessage message={getHelpMessage("delete_document")}>
                            <div>
                              <i class="tio-delete"></i> Eliminar
                            </div>
                          </HelpMessage>
                        </a>

                        {!document.isClosed ? (
                          <Link class="dropdown-item" to={`/${document.request.id}/${document.id}/edit`}>
                            <HelpMessage message={getHelpMessage("edit_document")}>
                              <div>
                                <i class="tio-edit"></i> Editar HC
                              </div>
                            </HelpMessage>
                          </Link>
                        )
                          : null}
                      </div>
                    </div>
                  </>
                )}

              </td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
    <ModalRoute path="/create" width="900" height="500" component={QaRequest} reqDocuments={reqDocuments} />
    <ModalRoute path="/:documentId(\d+)/assign" width="600" component={ReviewerSave} reqDocuments={reqDocuments} />
    <ModalRoute path="/:documentId(\d+)/edit" width="600" component={ReviewerEdit} reqDocuments={reqDocuments} />
    <ModalRoute path="/:documentId(\d+)/history" width="900" height="500" component={History} />
    <ModalRoute path="/:documentId(\d+)/correction" width="600" component={Correction} reqDocuments={reqDocuments} />
    <ModalRoute path="/:documentId(\d+)/committee" width="800" component={Committee} reqDocuments={reqDocuments} />
    <ModalRoute path="/:requestId(\d+)/:documentId(\d+)/edit" width="900" height="500" component={QaRequestEdit} />

    <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
  </>

);

DocumentListView.propTypes = {
  trash: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  reqDocuments: PropTypes.object,
  onClickPage: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onRestoreDocument: PropTypes.func,
  getLastReview: PropTypes.func.isRequired
};

export default DocumentListView;
import React from "react";
import PropTypes from "prop-types";
import View from "components/form/NoVerify.view";
import { useLocation } from "react-router";

function NoVerify() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const status = query.get("status");
  return <View status={status} />;
}

NoVerify.propTypes = {};

export default NoVerify;

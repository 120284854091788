import React, { useState } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { useLocation } from "react-router";
import View from "components/form/QaRequest.view";
import NowVerified from "components/form/NowVerified";
import { usePost, useGet } from "seed/api";
import { Loading } from "seed/helpers";
import { IS_PROD } from "settings";
import { FORM_SCHEMA, INITIAL_FORM } from "utils";
import NotFound from "components/utils/NotFound";

function QaRequest({ history }) {
  const [submitButton, setSubmitButton] = useState("");
  const date = new Date();
  const [callLoading, setCallLoading] = useState(false);
  const [stage, setStage] = useState("");
  const [comment, setComment] = useState("");
  const [queryPermS, setQueryPermS] = useState({ value: "", hidden: false });
  const [formatSignS, setFormatSignS] = useState({ value: "", hidden: false });
  const [isTranslationS, setIsTranslationS] = useState({
    value: "",
    hidden: false,
  });
  const [isComplementS, setIsComplementS] = useState({
    value: "",
    hidden: false,
  });
  const [isSubstitutionS, setIsSubstitutionS] = useState({
    value: "",
    hidden: false,
  });
  const [codFiscalFederationS, setCodFiscalFederationS] = useState({
    value: "",
  });
  const [docReportTypeS, setDocReportTypeS] = useState({
    value: "",
    hidden: false,
  });
  const [art5aS, setArt5aS] = useState({ value: "", hidden: false });
  const [art199S, setArt199S] = useState({ value: "", hidden: false });
  const [desingSchemeS, setDesingSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [clientReportS, setClientReportS] = useState({
    value: "",
    hidden: false,
  });
  const [art197S, setArt197S] = useState({ value: "", hidden: false });
  const [impetimentLegalS, setImpetimentLegalS] = useState({
    value: "",
    hidden: false,
  });
  const [art197ConstS, setArt197ConstS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveSchemaS, setRelieveSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [mountSchemaS, setMountSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [firstComercializationS, setFirstComercializationS] = useState({
    value: "",
    hidden: false,
  });
  const [art201S, setArt201S] = useState({ value: "", hidden: false });
  const [declarationInfoS, setDeclarationInfoS] = useState({
    value: "",
    hidden: false,
  });
  const [otherAsesorsS, setOtherAsesorsS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveAsesorS, setRelieveAsesorS] = useState({
    value: "",
    hidden: false,
  });
  const [reportableGeneralS, setReportableGeneralS] = useState({
    value: "",
    hidden: false,
  });
  const [art197pa6S, setArt197pa6S] = useState({ value: "", hidden: false });
  const [asesorArt197pa6S, setAsesorArt197pa6S] = useState({
    value: "",
    hidden: false,
  });
  const [constRelieveSchemeS, setConstRelieveSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [eptOrValuationS, setEptOrValuationS] = useState({ value: "", hidden: false })
  const [ccPrevS, setCcPrevS] = useState({ value: "", hidden: false })
  const [eptMov, setEptMov] = useState([{ dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }]);
  const [inputPartners, setInputPartners] = useState([]);
  const [inputAssociated, setInputAssociated] = useState([]);
  const [inputPartnersStage, setInputPartnersStage] = useState([]);
  const [inputAssociatedStage, setInputAssociatedStage] = useState([]);
  const [areas, setAreas] = useState([{ area: "" }]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const NdUserId = localStorage.getItem("NdUserId");
  const NdDocumentId = localStorage.getItem("NdDocumentId");

  const request = query.get("id");

  const reqUsers = useGet("/users");
  const reqMetadata = useGet(
    "/approvals/nd_metadata",
    { nd_user_id: NdUserId, nd_document_id: NdDocumentId });

  const reqApproval = useGet(
    "/requests/" + request + "/get_approval",
    {},
    {
      onCompleted: (data) => {
        const { approval, version } = data;
        if (version == "1") history.replace("/requestv1?id=" + request);
        else {
          if (
            approval.associated != undefined &&
            approval.partners != undefined
          ) {
            const { associated, partners, comments, body } = approval;
            const { emittingArea, eptMov } = body;
            partners[partners.length - 1].sent
              ? setStage("partners")
              : setStage("associated");
            partners.splice(-1, 1);
            setInputAssociated(associated);
            setInputPartners(partners);
            setInputAssociatedStage(associated);
            setInputPartnersStage(partners);
            setAreas(emittingArea);
            setComment(comments);
            setEptMov(eptMov);
          }
        }
      }
    }
  );

  const reqAssociate = useGet(
    "/requests/" + request + "/get_associate",
    {},
    {
      onCompleted: (data) => {
        setInputAssociated([
          ...inputAssociated,
          {
            name: data.associated.name,
            email: data.associated.email,
            approval: false,
            notify: false,
            date_send:
              date.getDate() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getFullYear(),
            followup_frequency: 1,
          },
        ]);
        setInputPartners([
          ...inputPartners,
          {
            name: data.partners.name,
            email: data.partners.email,
            approval: false,
            date_send:
              date.getDate() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getFullYear(),
            followup_frequency: 1,
          },
        ]);
      }
    }
  );

  const onCompletedRequest = (message) => {
    if (message == "Error S/A") {
      swal({
        title: "No se puede procesar la petición",
        icon: "error",
        text: "Debe agregar al menos un socio o asociado.",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-error",
          },
        },
      });
    } else {
      if (message == "Error") {
        swal({
          title: "No se puede procesar la petición",
          icon: "error",
          text: "La información no ha sido enviada.",
          buttons: {
            confirm: {
              text: "Ok",
              className: "swal-button swal-button--cancel btn-error",
            },
          },
        });
      } else {
        swal({
          title: "Contenido enviado",
          icon: "success",
          text: "La información fue enviada con éxito",
          buttons: {
            confirm: {
              text: "Ok",
              className: "swal-button swal-button--cancel btn-success",
            },
          },
        }).then((respuesta) => {
          if (respuesta) {
            window.close();
          }
        });
      }
    }
  };

  const [callForm, reqForm] = usePost("/approvals/create_approval", {
    onCompleted: (data) => {
      setCallLoading(false);
      const { message } = data;
      onCompletedRequest(message);
    }
  });

  const [callResend, reqResend] = usePost("/approvals/resend_emails", {
    onCompleted: (data) => {
      swal({
        title: "Correos enviados",
        icon: "success",
        text: "La información fue reenviada con éxito",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.close();
        }
      });
    }
  });

  const [callDraft, reqDraft] = usePost("/approvals/create_draft", {
    onCompleted: (data) => {
      setCallLoading(false);
      const { message } = data;
      onCompletedRequest(message);
    }
  });

  const onSubmit = (values) => {
    values.queryPerm =
      queryPermS.value == "" ? values.queryPerm : queryPermS.value;
    values.formatSignCheck =
      formatSignS.value == "" ? values.formatSignCheck : formatSignS.value;
    values.isTranslation =
      isTranslationS.value == "" ? values.isTranslation : isTranslationS.value;
    values.isComplement =
      isComplementS.value == "" ? values.isComplement : isComplementS.value;
    values.isSubstitution =
      isSubstitutionS.value == ""
        ? values.isSubstitution
        : isSubstitutionS.value;
    values.isReportable =
      codFiscalFederationS.value == ""
        ? values.isReportable
        : codFiscalFederationS.value;
    values.docReportType =
      docReportTypeS.value == "" ? values.docReportType : docReportTypeS.value;
    values.art5a = art5aS.value == "" ? values.art5a : art5aS.value;
    values.art199 = art199S.value == "" ? values.art199 : art199S.value;
    values.desingScheme =
      desingSchemeS.value == "" ? values.desingScheme : desingSchemeS.value;
    values.clientReport =
      clientReportS.value == "" ? values.clientReport : clientReportS.value;
    values.art197 = art197S.value == "" ? values.art197 : art197S.value;
    values.impetimentLegal =
      impetimentLegalS.value == ""
        ? values.impetimentLegal
        : impetimentLegalS.value;
    values.art197Const =
      art197ConstS.value == "" ? values.art197Const : art197ConstS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.mountSchema =
      mountSchemaS.value == "" ? values.mountSchema : mountSchemaS.value;
    values.firstComercialization =
      firstComercializationS.value == ""
        ? values.firstComercialization
        : firstComercializationS.value;
    values.reportableGeneral =
      reportableGeneralS.value == ""
        ? values.reportableGeneral
        : reportableGeneralS.value;
    values.art201 = art201S.value == "" ? values.art201 : art201S.value;
    values.declarationInfo =
      declarationInfoS.value == ""
        ? values.declarationInfo
        : declarationInfoS.value;
    values.otherAsesors =
      otherAsesorsS.value == "" ? values.otherAsesors : otherAsesorsS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.art197pa6 =
      art197pa6S.value == "" ? values.art197pa6 : art197pa6S.value;
    values.asesorArt197pa6 =
      asesorArt197pa6S.value == ""
        ? values.asesorArt197pa6
        : asesorArt197pa6S.value;
    values.relieveSchema =
      relieveSchemaS.value == "" ? values.relieveSchema : relieveSchemaS.value;
    values.constRelieveScheme =
      constRelieveSchemeS.value == ""
        ? values.constRelieveScheme
        : constRelieveSchemeS.value;
    values.emittingArea = areas;
    values.eptOrValuation = eptOrValuationS.value == "" ? values.eptOrValuation : eptOrValuationS.value;
    values.ccPrev = ccPrevS.value == "" ? values.ccPrev : ccPrevS.value;
    values.eptMov = eptMov;

    swal({
      title:
        submitButton == "draft" ? "Guardar como borrador" : "Enviar a Firmar",
      icon: submitButton == "draft" ? "info" : "warning",
      text:
        submitButton == "draft"
          ? "El contenido no sera enviado a los socios/asociados"
          : "Acepto el contenido de la hoja de control de calidad",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((respuesta) => {
      const partners = [...inputPartners, { sent: false }];
      if (respuesta) {
        const dataForm = {
          body: values,
          status: submitButton == "draft" ? "DRAFT" : "SENT",
          user_id: NdUserId,
          request_id: request,
          associated: inputAssociated,
          partners: partners,
          comments: comment,
        };
        submitButton == "draft" ? callDraft(dataForm) : callForm(dataForm);
        setCallLoading(true);
      }
    });
  };

  const addPartner = () => {
    setInputPartners([
      ...inputPartners,
      {
        email: "",
        name: "",
        approval: false,
        date_send:
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear(),
        followup_frequency: 1,
      },
    ]);
  };

  const addArea = () => {
    setAreas([...areas, { area: "" }]);
  };

  const addEptMov = () => {
    setEptMov([...eptMov, { dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }])
  }

  const setAssociated = (associated) => {
    setInputAssociated(associated);
  };

  const removePartner = (index) => {
    const values = [...inputPartners];
    values.splice(-1, 1);
    setInputPartners(values);
  };

  const removeArea = (index) => {
    const values = [...areas];
    values.splice(-1, 1);
    setAreas(values);
  };

  const removeEptMov = (index) => {
    const values = [...eptMov];
    values.splice(-1, 1);
    setEptMov(values);
  }

  const addAssociated = () => {
    setInputAssociated([
      ...inputAssociated,
      {
        email: "",
        name: "",
        approval: false,
        notify: false,
        date_send:
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear(),
        followup_frequency: 1,
      },
    ]);
  };

  const removeAssociated = (index) => {
    const values = [...inputAssociated];
    values.splice(-1, 1);
    setInputAssociated(values);
  };

  const onResend = (email, stage) => {
    swal({
      title: "Reenviar Correo",
      icon: "warning",
      text: "Se notificara nuevamente al socio/asociado",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((respuesta) => {
      if (respuesta) {
        callResend({ request_id: request, email: email, approval: stage });
        setCallLoading(true);
      }
    });
  };

  const onChangePartners = (index, event) => {
    const values = [...inputPartners];
    values[index][event.target.name] = event.target.value;
    let i = event.target.selectedIndex;
    values[index].name = event.target.options[i].text;
    setInputPartners(values);
  };

  const onChangeArea = (index, event) => {
    const values = [...areas];
    values[index].area = event.target.value;
    setAreas(values);
  };

  const onChangeEptMov = (index, event) => {
    const values = [...eptMov];
    values[index][event.target.name] = event.target.value;
    setEptMov(values);
  }

  const onChangeAssociated = (index, event) => {
    const values = [...inputAssociated];
    values[index][event.target.name] = event.target.value;
    let i = event.target.selectedIndex;
    values[index].name = event.target.options[i].text;
    setInputAssociated(values);
  };

  const onChangeAssociatedNotify = (index, event) => {
    const values = [...inputAssociated];
    values[index].notify = !values[index].notify;
    values[index].approval = !values[index].approval;
    setInputAssociated(values);
  };

  if (
    reqUsers.loading ||
    reqApproval.loading ||
    reqMetadata.loading ||
    callLoading
  )
    return <Loading />;
  if (reqUsers.error) return "error";
  if (request == "") return <NotFound />;

  const ndMetadata = reqMetadata.data;
  const dataPartnersAssociated = reqUsers.data;
  const approvalData = reqApproval.data;
  const approval = approvalData.approval;

  const getMetadataValue = (metadata, id) => {
    if (metadata.customAttributes == null) return "";
    let attrs = metadata.customAttributes;
    let ele = attrs.filter((attr) => attr.id == id);
    if (ele.length == 0) return "";
    return ele[0].description != null ? ele[0].description : ele[0].value;
  };

  let initialForm = Object.assign({}, INITIAL_FORM);
  /* TODO UPDATE PRODUCTION KEYS
  initialForm.nameClient = getMetadataValue(ndMetadata, 16);
  initialForm.emittingArea = getMetadataValue(ndMetadata, 13);
  initialForm.documentType = getMetadataValue(ndMetadata, 14);
  initialForm.description = getMetadataValue(ndMetadata, 7);
  */
  if (
    !approvalData.status ||
    approval.status == "REJECTED" ||
    approval.status == "DRAFT"
  ) {
    return (
      <View
        status={approval.status == "REJECTED" ? approval.status : ""}
        formData={
          approval.status == "REJECTED" || approval.status == "DRAFT"
            ? approval.body
            : initialForm
        }
        comments={
          approval.status == "REJECTED" ? approval.comments.split(":")[0] : ""
        }
        queryPermS={queryPermS}
        formatSignS={formatSignS}
        setQueryPermS={setQueryPermS}
        setFormatSignS={setFormatSignS}
        onSubmit={onSubmit}
        addPartner={addPartner}
        removePartner={removePartner}
        addAssociated={addAssociated}
        removeAssociated={removeAssociated}
        inputPartners={inputPartners}
        inputAssociated={inputAssociated}
        onChangePartners={onChangePartners}
        onChangeAssociated={onChangeAssociated}
        FormSchema={FORM_SCHEMA}
        dataPartnersAssociated={dataPartnersAssociated}
        codFiscalFederationS={codFiscalFederationS}
        setCodFiscalFederationS={setCodFiscalFederationS}
        isTranslationS={isTranslationS}
        setIsTranslationS={setIsTranslationS}
        isComplementS={isComplementS}
        setIsComplementS={setIsComplementS}
        isSubstitutionS={isSubstitutionS}
        setIsSubstitutionS={setIsSubstitutionS}
        setSubmitButton={setSubmitButton}
        onChangeAssociatedNotify={onChangeAssociatedNotify}
        docReportTypeS={docReportTypeS}
        setDocReportTypeS={setDocReportTypeS}
        art5aS={art5aS}
        setArt5aS={setArt5aS}
        art199S={art199S}
        setArt199S={setArt199S}
        desingSchemeS={desingSchemeS}
        setDesingSchemeS={setDesingSchemeS}
        clientReportS={clientReportS}

        setClientReportS={setClientReportS}
        art197S={art197S}
        setArt197S={setArt197S}
        impetimentLegalS={impetimentLegalS}
        setImpetimentLegalS={setImpetimentLegalS}
        art197ConstS={art197ConstS}
        setArt197ConstS={setArt197ConstS}
        relieveSchemaS={relieveSchemaS}
        setRelieveSchemaS={setRelieveSchemaS}
        mountSchemaS={mountSchemaS}
        setMountSchemaS={setMountSchemaS}
        firstComercializationS={firstComercializationS}
        setFirstComercializationS={setFirstComercializationS}
        declarationInfoS={declarationInfoS}
        setDeclarationInfoS={setDeclarationInfoS}
        art201S={art201S}
        setArt201S={setArt201S}
        reportableGeneralS={reportableGeneralS}
        setReportableGeneralS={setReportableGeneralS}
        otherAsesorsS={otherAsesorsS}
        setOtherAsesorsS={setOtherAsesorsS}
        relieveAsesorS={relieveAsesorS}
        setRelieveAsesorS={setRelieveAsesorS}
        art197pa6S={art197pa6S}
        setArt197pa6S={setArt197pa6S}
        asesorArt197pa6S={asesorArt197pa6S}
        setAsesorArt197pa6S={setAsesorArt197pa6S}
        constRelieveSchemeS={constRelieveSchemeS}
        setConstRelieveSchemeS={setConstRelieveSchemeS}
        addArea={addArea}
        removeArea={removeArea}
        onChangeArea={onChangeArea}
        areas={areas}
        eptOrValuationS={eptOrValuationS}
        setEptOrValuationS={setEptOrValuationS}
        eptMov={eptMov}
        setEptMov={setEptMov}
        addEptMov={addEptMov}
        removeEptMov={removeEptMov}
        onChangeEptMov={onChangeEptMov}
        ccPrevS={ccPrevS}
        setCcPrevS={setCcPrevS}
        IS_PROD={IS_PROD}
      />
    );
  } else {
    if (approval.status == "SIGNED") {
      return <NowVerified status={"SIGNED"} />;
    } else {
      return (
        <NowVerified
          status={""}
          request={request}
          onResend={onResend}
          inputAssociated={inputAssociatedStage}
          inputPartners={inputPartnersStage}
          stage={stage}
        />
      );
    }
  }
}

QaRequest.propTypes = {};

export default QaRequest;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/correction/Committee.view";

function Committee({ onCompleted = () => null, onError = () => null, reqDocuments, documentId }) {

  const [error, setError] = useState(null);
  const [selectedIdxReview, setSelectedIdxReview] = useState(0);
  const [selectedReview, setSelectedReview] = useState(null);

  const [callSaveCommittee, reqSaveCommittee] = usePost("/documents/create_committee", {
    onCompleted: () => {
      swal({
        title: "Comité creado exitosamente",
        icon: "success",
        text: "El comité se ha registrado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/cc_home";
        }
      });
    },
    onError: (data) => {
      console.log(data);
      if (data.status == 401) {
        setError("El documento no esta revisado, por favor reinicie la página");
      } else {
        setError("Error interno del servidor, revise sus entradas e intente de nuevo");
      }
    }
  });

  const reqDocument = useDetail(`{
    document {
      data
      createdAt
      updatedAt
      status
      request {
        ndDocumentId
      }
      file {
        url
      }
      committees {}
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/cc_home";
    }
  })

  const reqReviews = useQuery(`{
    reviews {
      createdAt
      updatedAt
      hasComments
      needsCommittee
      comments
      dateReviewed
      dateApproved
      reviewer {
        email
        lastName
        firstName
      }
    }
  }`, `document.id = ${documentId} AND (needs_committee=True OR has_comments=True)`, {
    onCompleted: (data) => {
      if (data.reviews.length > 0) {
        setSelectedReview({ ...data.reviews[0] });
      }
    },
    onError: (error) => {
      console.log(error);
      window.location.href = "/cc_home";
    },
    orderBy: "date_reviewed"
  })

  const reqUsers = useQuery(`{
    users {
      email
      lastName
      firstName
    }
  }`, "", { orderBy: "first_name" })

  if (reqDocument.loading || reqReviews.loading || reqUsers.loading) return <Loading />;
  if (reqDocument.error || reqReviews.error || reqUsers.error) return "Error";

  const { reviews = [] } = reqReviews.data;
  const { document = {} } = reqDocument.data;
  const { users = [] } = reqUsers.data;

  if (document.status != "NEEDS_COMMITTEE")
    window.location.href = "/cc_home";

  const onSubmit = (values) => {
    const date = values["date"];
    const data = values["data"];
    const participants = values["participants"];
    const decision = values["decision"];

    if (!date || !data || !participants || !decision) {
      setError("Debe llenar todos los campos");
      return;
    }

    if (participants.length == 0) {
      setError("Debe seleccionar al menos a un participante")
      return;
    }

    callSaveCommittee({
      documentId: documentId,
      date: date,
      data: data,
      participants: participants,
      decision: decision
    })
  }

  const onChangeSelectedReview = (event, newValue) => {
    setSelectedIdxReview(newValue);
    setSelectedReview({ ...reviews[newValue] });
  }

  return (
    <View
      reviews={reviews}
      document={document}
      selectedIdxReview={selectedIdxReview}
      selectedReview={selectedReview}
      error={error}
      participants={users}
      onSubmit={onSubmit}
      onChangeSelectedReview={onChangeSelectedReview}
    />
  );
}

Committee.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  reqDocuments: PropTypes.object,
  documentId: PropTypes.string,
  props: PropTypes.object,
  match: PropTypes.object
};

export default Committee;
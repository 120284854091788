import * as Yup from "yup";

const FORM_SCHEMA = Yup.object().shape({
  nameClient: Yup.string().required("Debe ingresar el nombre del cliente"),
  documentDate: Yup.string().required("Debe ingresar la fecha del documento"),
  desOrganization: Yup.string().required(
    "Debe ingresar el nombre una asociación"
  ),
  initialsCheck: Yup.string().required("Ingrese las iniciales del chequeo"),
  initialsReviewer: Yup.string().required(
    "Ingrese las iniciales del revisor"
  ),
  recordDoc: Yup.string().required("Indique si se realizó la acción"),
  investigationDoc: Yup.string().required("Indique si se realizo la acción"),
  supportedDoc: Yup.string().required("Indique si se realizó la acción"),
  conclusionSuppDoc: Yup.string().required("Indique si se realizó la acción"),
  additionalReview: Yup.string().required("Indique si se realizó la acción"),
  taxationFormat: Yup.string().required("Indique si se realizó la acción"),
  nonBindingCrit: Yup.string().required("Indique si se realizó la acción"),
  difNonBindingCrit: Yup.string().required("Indique si se realizó la acción"),
});

const INITIAL_FORM = {
  "nameClient": "",
  "desOrganization": "",
  "emittingArea": "",
  "queryPerm": "",
  "initialsCheck": "",
  "initialsReviewer": "",
  "formatSignCheck": "",
  "documentDate": "",  
  "noFormatSign": "",
  "isTranslation": "",
  "nameTraslate": "",
  "isComplement": "",
  "isComplementExplain": "",
  "dateDocComplement": "",
  "isSubstitution": "",
  "isSubstitutionExplain": "",
  "dateDocSubstitution": "",
  "recordDoc": "",
  "investigationDoc": "",
  "supportedDoc": "",
  "riskInfoDoc": "",
  "conclusionSuppDoc": "",
  "additionalReview": "",
  "committeReview": "",
  "taxationFormat": "",
  "isReportable": "",
  "docReportType": "",
  "art5a": "",
  "art199": "",
  "desingScheme": "",
  "clientReport": "",
  "clientReportExplainA": "",
  "clientReportExplainB": "",
  "art197": "",
  "art197Explain": "",
  "impetimentLegal": "",
  "art197Const": "",
  "art197ConstExplain": "",
  "relieveSchema": "",
  "mountSchema": "",
  "mountSchemaExplain": "",
  "citReportable": "",
  "reportableGeneral": "",
  "firstComercialization": "",
  "declarationInfo": "",
  "declarationInfoExplain": "",
  "art201": "",
  "art201Explain": "",
  "otherAsesors": "",
  "nameOtherAsesors": "",
  "relieveAsesor": "",
  "relieveAsesorExplain": "",
  "relieveAsesorName": "",
  "art197pa6": "",
  "art197pa6Explain": "",
  "asesorArt197pa6": "",
  "asesorArt197pa6Explain": "",
  "constRelieveScheme": "",
  "constRelieveSchemeExplain": "",
  "eptOrValuation": "",
  "nonBindingCrit": "",
  "difNonBindingCrit": "",
  "nameClientTransfer": "",
  "nameAnalitic": "",
  "dateControlQuality": "",
  "ccPrev": "",
  "ccPrevExplain": "",
  "staffInitials": "",
  "seniorInitials": "",
  "revisorInitials": "",
  "dateDataBase": "",
  "financeInfo": "",
  "rejectRI": "",
  "listReport": "",
  "betaSupport": "",
  "betaProcess": "",
  "CAPM": "",
  "WACC": "",
  "proyectionFile": "",
  "presentValue": "",
  "perptCalc": "",
  "resultFile": "",
  "eptMov": "",
}

export { FORM_SCHEMA, INITIAL_FORM };

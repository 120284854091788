import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const ReviewerView = ({ partners = [], onSubmit = () => { }, error, document = {}, initialReviewers = [], edit = false }) => (
  <div class="card">
    {/* Header */}
    <div class="card-header">
      <h4 class="card-header-title">REVISOR</h4>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik initialValues={document} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div class="mb-3">
                  {/* Partner */}
                  <div class="form-group">
                    <label class="input-label" for="reviewers">
                      Listado de Socios
                    </label>

                    <Autocomplete
                      multiple
                      id="reviewers"
                      size="small"
                      defaultValue={initialReviewers}
                      options={partners}
                      isOptionEqualToValue={(option, value) => option.id == value.id}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.firstName + " " + option.lastName}
                      getOptionKey={(option) => option.id}
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.firstName + " " + option.lastName}
                          </li>
                        );
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="Selecciones los socios revisores" />
                      )}
                      onChange={(event, newValue) => {
                        // console.log("newValue", newValue)
                        setFieldValue("reviewers", newValue.map((partner) => partner.id));
                      }}
                    />
                  </div>

                  {/* Deadline */}
                  <div class="form-group">
                    <label class="input-label">Fecha Límite</label>
                    <Field type="date" name="deadline" class="form-control" />
                  </div>

                </div>
                {error ? <div class="alert alert-soft-danger">{error}</div> : null}

                {edit ? (
                  <>
                    <button type="button" class="btn btn-block btn-primary mb-3"
                      onClick={() => onSubmit(values, false)}
                    >MODIFICAR</button>
                    <button
                      onClick={() => onSubmit(values, true)}
                      type="button" class="btn btn-block btn-warning">
                      <i class="far fa-envelope mr-3"></i>INICIAR REVISIÓN
                    </button>
                  </>
                ) : (
                  <button type="submit" class="btn btn-block btn-primary">ASIGNAR</button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>
);

ReviewerView.propTypes = {
  partners: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  edit: PropTypes.bool,
  document: PropTypes.object,
  buttonText: PropTypes.string
};

export default ReviewerView;
import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const QaApprovalV1 = ({
  documentId,
  name,
  formData,
  users,
  onSubmitRejected,
  docTypeS,
  queryPermS,
  otherDateSendS,
  docIsS,
  formatSignS,
  formatEnS,
  notifyReportS,
  setDocTypeS,
  setQueryPermS,
  setFormatSignS,
  setFormatEnS,
  setDocIsS,
  setNotifyReportS,
  setOtherDateSendS,
  onSubmit,
  FormSchema,
  status,
  comments,
  isUserRejeted,
  codFiscalFederationS,
  setCodFiscalFederationS,
}) => (
  <main
    id="content"
    role="main"
    class="main"
    style={{ "padding-left": "2.25rem" }}
  >
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <h1
          class="mt-2 display-4"
          style={{
            position: "absolute",
            "z-index": "3",
            color: "white",
            "margin-right": "22.5rem",
          }}
        >
          Hoja Crono
        </h1>
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <Formik
        initialValues={formData}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div class="card card-lg mb-5">
                  <div class="card-body">
                    <div
                      style={{
                        color: "#41464b",
                        "background-color": "#e2e3e5",
                        "border-color": "#d3d6d8",
                        "text-align": "justify",
                      }}
                      class="alert mb-6"
                      role="alert"
                    >
                      <div class="row mt-3">
                        <div class="col-1">
                          <figure
                            style={{
                              position: "absolute",
                              "z-index": "1",
                              "border-radius": "30px",
                              width: "40px",
                              height: "40px",
                              overflow: "hidden",
                              margin: "0",
                              padding: "0",
                              background: "white",
                              border: "2px solid rgba(255, 255, 255, 0.24)",
                            }}
                          >
                            <img
                              style={{ width: "100%", height: "auto" }}
                              src="https://www.seekpng.com/png/full/514-5147412_default-avatar-icon.png"
                            />
                          </figure>
                        </div>
                        <div class="col-8">
                          <h5 style={{ "margin": "0px" }}>{users.first_name} {users.last_name}</h5>
                          <p>{users.email}</p>
                        </div>
                      </div>
                      <b>Instrucciones:</b>
                      <ol>
                        <li>Verifica la información.</li>
                        <li>Realiza los cambios que considere necesarios.</li>
                        <li>Apruebe o rechace la solicitud.</li>
                      </ol>
                    </div>

                    <h2 class='pb-3'>
                      Control de Calidad{" "}
                      {/*status == "REJECTED" ? (
                        <span
                          class="badge bg-danger text-light"
                        >
                          {status}
                        </span>
                      ) : status == "CORRECTED" ? (
                        <span
                          class="badge bg-primary text-light"
                        >
                          {status}
                        </span>
                      ) : status == "SENT" ? (
                        <span
                          class="badge bg-secondary text-light"
                        >
                          {status}
                        </span>
                      ) : (
                        <span
                          class="badge bg-success text-light"
                        >
                          {status}
                        </span>
                      )*/}
                      <a href={`https://vault.netvoyage.com/neWeb2/goid.aspx?id=${documentId}`} class="btn-soft-info ml-3 px-3 py-2 h4 font-weight-normal" rel="noreferrer" target="_blank">Ir a documento: <i>{"\"" + name + "\""}</i></a>
                    </h2>

                    <h3>Aspectos Control Interno</h3>
                    {status == "CORRECTED" && isUserRejeted ? (
                      comments != "" ? (
                        <div
                          class="alert"
                          style={{
                            color: "#664d03",
                            "background-color": "#fff3cd",
                            "border-color": "#ffecb5",
                          }}
                          role="alert"
                        >
                          Correcciones atendidas: {comments}
                        </div>
                      ) : (
                        <div
                          class="alert"
                          style={{
                            color: "#664d03",
                            "background-color": "#fff3cd",
                            "border-color": "#ffecb5",
                          }}
                          role="alert"
                        >
                          Correcciones atendidas: No se recibieron comentarios.
                        </div>
                      )
                    ) : null}
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-4 col-form-label input-label"
                      >
                        Nombre del Cliente (FILE){" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-8">
                        <Field
                          type="text"
                          class="form-control"
                          name="nameClient"
                          required
                        />
                        {errors.nameClient && touched.nameClient ? (
                          <p class="text-danger mt-2">{errors.nameClient}</p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-4 col-form-label input-label"
                      >
                        Compañía a quien se dirige la carta{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-8">
                        <Field
                          type="text"
                          class="form-control"
                          name="desOrganization"
                          required
                        />
                        {errors.desOrganization && touched.desOrganization ? (
                          <p class="text-danger mt-2">
                            {errors.desOrganization}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div class="row form-group">
                      <label
                        for="documentDateLabel"
                        class="col-sm-4 col-form-label input-label"
                      >
                        Fecha del documento {" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-8">
                        <Field
                          type="date"
                          class="form-control"
                          name="documentDate"
                          required
                        />
                        {errors.documentDate &&
                          touched.documentDate ? (
                          <p class="text-danger mt-2">
                            {errors.documentDate}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Área Emisora <b style={{ color: "red" }}>*</b>
                      </label>

                      <div class="col-sm-3">
                        <Field
                          component="select"
                          name="emittingArea"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="Administrativo">Administrativo</option>
                          <option value="Comercio">Comercio</option>
                          <option value="Contribuciones locales">Contribuciones locales</option>
                          <option value="Fiscal">Fiscal</option>
                          <option value="Laboral">Laboral</option>
                          <option value="Legal">Legal</option>
                          <option value="Legal Admo">Legal Admo</option>
                          <option value="Patrimonial">Patrimonial</option>
                          <option value="Precios de transferencia">Precios de transferencia</option>
                          <option value="Propiedad Intelectual">Propiedad Intelectual</option>
                          <option value="Seguridad Social">Seguridad Social</option>
                          <option value="Transaccional">Transaccional</option>
                          <option value="Otra">Otra</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.emittingArea && touched.emittingArea ? (
                          <p class="text-danger mt-2">{errors.emittingArea}</p>
                        ) : null}
                      </div>
                      <label
                        for="organizationLabel"
                        class="col-sm-1 col-form-label input-label"
                      >
                        Otra
                      </label>
                      <div class="col-sm-2">
                        <Field
                          component="select"
                          name="otherArea"
                          class="form-control custom-select"
                        >
                          <option value="">...</option>
                          <option value="Administrativo">Administrativo</option>
                          <option value="Comercio">Comercio</option>
                          <option value="Contribuciones locales">Contribuciones locales</option>
                          <option value="Fiscal">Fiscal</option>
                          <option value="Laboral">Laboral</option>
                          <option value="Legal">Legal</option>
                          <option value="Legal Admo">Legal Admo</option>
                          <option value="Patrimonial">Patrimonial</option>
                          <option value="Precios de transferencia">Precios de transferencia</option>
                          <option value="Propiedad Intelectual">Propiedad Intelectual</option>
                          <option value="Seguridad Social">Seguridad Social</option>
                          <option value="Transaccional">Transaccional</option>
                          <option value="Otra">Otra</option>
                          <option value="NA">NA</option>
                        </Field>
                      </div>
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        No. de hojas <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-2">
                        <Field
                          type="number"
                          class="form-control"
                          name="pageNum"
                          required
                        />
                        {errors.pageNum && touched.pageNum ? (
                          <p class="text-danger mt-2">{errors.pageNum}</p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Tipo de Documento <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="documentType"
                          class="form-control custom-select"
                          value={
                            docTypeS.value == ""
                              ? formData.documentType
                              : docTypeS.value
                          }
                          required
                          onChange={(event) =>
                            setDocTypeS({
                              value: event.target.value,
                              hidden:
                                event.target.value == "Otro" ? true : false,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="Carta Auditores">
                            Carta Auditores
                          </option>
                          <option value="Carta Envio">Carta Envio</option>
                          <option value="Carta Informativa">
                            Carta Informativa
                          </option>
                          <option value="Carta Propuesta">
                            Carta Propuesta
                          </option>
                          <option value="Estudio de PT">Estudio de PT</option>
                          <option value="Memo">Memo</option>
                          <option value="Opinion">Opinion</option>
                          <option value="Solicitud de Pago">
                            Solicitud de Pago
                          </option>
                          <option value="Valuacion de PT">
                            Valuacion de PT
                          </option>
                          <option value="Otro">Otro</option>
                        </Field>
                        {errors.documentType &&
                          touched.documentType &&
                          docTypeS.value == "" ? (
                          <p class="text-danger mt-2">{errors.documentType}</p>
                        ) : null}
                      </div>
                      {docTypeS.hidden ? (
                        <>
                          <label
                            for="organizationLabel"
                            class="col-sm-2 col-form-label input-label"
                          >
                            Describir
                          </label>
                          <div class="col-sm-4">
                            <Field
                              type="text"
                              class="form-control"
                              name="otherDocType"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Descripción <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          type="text"
                          class="form-control"
                          name="description"
                          required
                        />
                        {errors.description && touched.description ? (
                          <p class="text-danger mt-2">{errors.description}</p>
                        ) : null}
                      </div>
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Concepto General <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="grlConcept"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="Comercio Exterior">
                            Comercio Exterior
                          </option>
                          <option value="Consolidacion">Consolidacion</option>
                          <option value="Contribuciones Locales">
                            Contribuciones Locales
                          </option>
                          <option value="Fusiones y Adquisiciones">
                            Fusiones y Adquisiciones
                          </option>
                          <option value="Impuestos Federales">
                            Impuestos Federales
                          </option>
                          <option value="Inmobiliario">Inmobiliario</option>
                          <option value="Internacional">Internacional</option>
                          <option value="Legal Administrativo">
                            Legal Administrativo
                          </option>
                          <option value="Litigio">Litigio</option>
                          <option value="Precios de Transferencia">
                            Precios de Transferencia
                          </option>
                          <option value="Refipre / Patrimonial">
                            Refipre / Patrimonial
                          </option>
                          <option value="Seguridad Social">
                            Seguridad Social
                          </option>
                          <option value="Sistema Financiero">
                            Sistema Financiero
                          </option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.grlConcept && touched.grlConcept ? (
                          <p class="text-danger mt-2">{errors.grlConcept}</p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        En caso de tratarse de EPT o valuación, ¿se llenó la
                        hoja de control de calidad correspondiente?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="eptOrValuation"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.eptOrValuation && touched.eptOrValuation ? (
                          <p class="text-danger mt-2">
                            {errors.eptOrValuation}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Indicar si es <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="docIs"
                          class="form-control custom-select"
                          value={docIsS.value == ""
                            ? formData.docIs
                            : docIsS.value}
                          required
                          onChange={(event) => setDocIsS({ value: event.target.value, hidden: event.target.value == "NA" ? true : false })}
                        >
                          <option value="">...</option>
                          <option value="Complemento a Carta Previa">
                            Complemento a Carta Previa
                          </option>
                          <option value="Sustituye Misma Carta Previamente Enviada">
                            Sustituye Misma Carta Previamente Enviada
                          </option>
                          <option value="Version en Ingles de Carta en Español">
                            Version en Ingles de Carta en Español
                          </option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.docIs && touched.docIs && docIsS.value == "" ? (
                          <p class="text-danger mt-2">{errors.docIs}</p>
                        ) : null}
                      </div>
                      {docIsS.hidden ? <></> : <>
                        <label
                          for="organizationLabel"
                          class="col-sm-2 col-form-label input-label"
                        >
                          Fecha de Doc. original
                        </label>
                        <div class="col-sm-4">
                          <Field
                            type="date"
                            class="form-control"
                            name="dateDoc"
                          />
                        </div>
                      </>}
                    </div>
                    {docIsS.hidden ? <></> :
                      <div class="row form-group">

                        <label
                          for="organizationLabel"
                          class="col-sm-2 col-form-label input-label"
                        >
                          Original
                        </label>
                        <div class="col-sm-4">
                          <Field
                            type="text"
                            class="form-control"
                            name="original"
                          />
                        </div>
                        <label
                          for="organizationLabel"
                          class="col-sm-2 col-form-label input-label"
                        >
                          En caso de Doc. sustituto Explicar
                        </label>
                        <div class="col-sm-4">
                          <Field
                            type="text"
                            class="form-control"
                            name="docSubs"
                          />
                        </div>
                      </div>}
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Fecha de Envio <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          type="date"
                          class="form-control"
                          name="dateSend"
                          required
                        />
                        {errors.dateSend && touched.dateSend ? (
                          <p class="text-danger mt-2">{errors.dateSend}</p>
                        ) : null}
                      </div>
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Fecha distinta en Doc
                      </label>
                      <div class="col-sm-4">
                        <Field
                          type="date"
                          class="form-control"
                          name="otherDate"
                          value={
                            otherDateSendS.value == ""
                              ? formData.otherDate
                              : otherDateSendS.value
                          }
                          onChange={(event) => setOtherDateSendS({ value: event.target.value, hidden: (event.target.value != "" ? true : false) })}
                        />
                      </div>
                      {otherDateSendS.hidden ? <>
                        <label
                          for="organizationLabel"
                          class="col-sm-2 col-form-label input-label  mt-5"
                        >
                          Explique
                        </label>
                        <div class="col-sm-10 mt-5">
                          <Field
                            type="text"
                            class="form-control"
                            name="otherDateDes"
                          />
                        </div>
                      </> : null}
                    </div>
                    <hr
                      class="mb-2"
                      style={{ "border-top": "1px dashed green" }}
                    />
                    <h3 class="pb-3">Aspectos Formales</h3>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Debe ir a consulta permanente{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="queryPerm"
                          class="form-control custom-select"
                          value={
                            queryPermS.value == ""
                              ? formData.queryPerm
                              : queryPermS.value
                          }
                          required
                          onChange={(event) =>
                            setQueryPermS({
                              value: event.target.value,
                              hidden: event.target.value == "NO" ? true : false,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.queryPerm &&
                          touched.queryPerm &&
                          queryPermS.value == "" ? (
                          <p class="text-danger mt-2">{errors.queryPerm}</p>
                        ) : null}
                      </div>
                      {queryPermS.hidden ? (
                        <>
                          <label
                            for="organizationLabel"
                            class="col-sm-2 col-form-label input-label"
                          >
                            Explique
                          </label>
                          <div class="col-sm-4">
                            <Field
                              type="text"
                              class="form-control"
                              name="queryNoperm"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Iniciales del Chequeo <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          type="text"
                          class="form-control"
                          name="initialsCheck"
                          required
                        />
                        {errors.initialsCheck && touched.initialsCheck ? (
                          <p class="text-danger mt-2">{errors.initialsCheck}</p>
                        ) : null}
                      </div>
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Iniciales de Revisor <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          type="text"
                          class="form-control"
                          name="initialsReviewer"
                          required
                        />
                        {errors.initialsReviewer && touched.initialsReviewer ? (
                          <p class="text-danger mt-2">
                            {errors.initialsReviewer}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        ¿Se respetó el formato de firma?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="formatSignCheck"
                          class="form-control custom-select"
                          value={
                            formatSignS.value == ""
                              ? formData.formatSignCheck
                              : formatSignS.value
                          }
                          required
                          onChange={(event) =>
                            setFormatSignS({
                              value: event.target.value,
                              hidden: event.target.value == "NO" ? true : false,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.formatSignCheck &&
                          touched.formatSignCheck &&
                          formatSignS.value == "" ? (
                          <p class="text-danger mt-2">
                            {errors.formatSignCheck}
                          </p>
                        ) : null}
                      </div>
                      {formatSignS.hidden ? (
                        <>
                          <label
                            for="organizationLabel"
                            class="col-sm-2 col-form-label input-label"
                          >
                            Explique
                          </label>
                          <div class="col-sm-4">
                            <Field
                              type="text"
                              class="form-control"
                              name="noFormatSign"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-2 col-form-label input-label"
                      >
                        Se elaboró directamente en inglés{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-4">
                        <Field
                          component="select"
                          name="formatEn"
                          class="form-control custom-select"
                          value={
                            formatEnS.value == ""
                              ? formData.formatEn
                              : formatEnS.value
                          }
                          required
                          onChange={(event) =>
                            setFormatEnS({
                              value: event.target.value,
                              hidden: event.target.value == "NO" ? true : false,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.formatEn &&
                          touched.formatEn &&
                          formatEnS.value == "" ? (
                          <p class="text-danger mt-2">{errors.formatEn}</p>
                        ) : null}
                      </div>
                      {formatEnS.hidden ? (
                        <>
                          <label
                            for="organizationLabel"
                            class="col-sm-2 col-form-label input-label"
                          >
                            Nombre del traductor
                          </label>
                          <div class="col-sm-4">
                            <Field
                              type="text"
                              class="form-control"
                              name="nameTraslate"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <hr
                      class="mb-2"
                      style={{ "border-top": "1px dashed green" }}
                    />
                    <h3 class="pb-3">Aspectos Técnicos</h3>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿Se describen claramente los antecedentes?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="recordDoc"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.recordDoc && touched.recordDoc ? (
                          <p class="text-danger mt-2">{errors.recordDoc}</p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿La investigación realizada es adecuada y suficiente?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="investigationDoc"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.investigationDoc && touched.investigationDoc ? (
                          <p class="text-danger mt-2">
                            {errors.investigationDoc}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿El documento está debidamente fundamentado?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="supportedDoc"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.supportedDoc && touched.supportedDoc ? (
                          <p class="text-danger mt-2">{errors.supportedDoc}</p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        En su caso,¿Se le informa al cliente de los riesgos?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="riskInfoDoc"
                          class="form-control custom-select"
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿Las conclusiones se desprenden de los antecentes y
                        desarrollo del documento?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="conclusionSuppDoc"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.conclusionSuppDoc &&
                          touched.conclusionSuppDoc ? (
                          <p class="text-danger mt-2">
                            {errors.conclusionSuppDoc}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        Por los temas tratados,¿La debió revisar un socio de
                        otra área distinta a la(s) emisora(s)?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="additionalReview"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.additionalReview && touched.additionalReview ? (
                          <p class="text-danger mt-2">
                            {errors.additionalReview}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        En su caso,¿Debe remitirse al comité técnico?
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="committeReview"
                          class="form-control custom-select"
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        Si se analizan tratados para evitar la doble
                        tributación, ¿Se utilizó el apartado de alcance
                        adecuado? <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="taxationFormat"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.taxationFormat && touched.taxationFormat ? (
                          <p class="text-danger mt-2">
                            {errors.taxationFormat}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <hr
                      class="mb-2"
                      style={{ "border-top": "1px dashed green" }}
                    />
                    <h3 class="pb-3">Esquemas Reportables</h3>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿El documento contiene un esquema reportable de
                        conformidad con el título sexto del código fiscal de la
                        federación? <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="codFiscalFederation"
                          class="form-control custom-select"
                          value={
                            codFiscalFederationS.value == ""
                              ? formData.codFiscalFederation
                              : codFiscalFederationS.value
                          }
                          required
                          onChange={(event) =>
                            setCodFiscalFederationS({
                              value: event.target.value,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.codFiscalFederation &&
                          touched.codFiscalFederation ? (
                          <p class="text-danger mt-2">
                            {errors.codFiscalFederation}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        ¿Se llenó la hoja de control de calidad de esquemas
                        reportable correspodiente?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="pageControlQuality"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.pageControlQuality &&
                          touched.pageControlQuality ? (
                          <p class="text-danger mt-2">
                            {errors.pageControlQuality}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        En caso de que el documento contenga un esquema que
                        genere beneficios fiscales en México, pero no sea
                        reportable de conformidad con el artículo 199 del código
                        fiscal de la federación, ¿Se expidió constancia al
                        cliente en la que se justifiquen y motiven las razones
                        por las cuales dicho esquema no es reportable o exista
                        un impedimento para revelar el mismo en el plazo de 5
                        días? <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="notifyReport"
                          class="form-control custom-select"
                          value={
                            notifyReportS.value == ""
                              ? formData.notifyReport
                              : notifyReportS.value
                          }
                          required
                          onChange={(event) =>
                            setNotifyReportS({
                              value: event.target.value,
                              hidden: event.target.value == "NO" ? true : false,
                            })
                          }
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                      </div>
                    </div>
                    <div class="row form-group">
                      {notifyReportS.hidden ? (
                        <>
                          <label
                            for="organizationLabel"
                            class="col-sm-2 col-form-label input-label"
                          >
                            Explique
                          </label>
                          <div class="col-sm-10">
                            <Field
                              type="text"
                              class="form-control"
                              name="noNotifyReport"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <hr
                      class="mb-2"
                      style={{ "border-top": "1px dashed green" }}
                    />
                    <h3 class="pb-3">Criterio no vinculativos</h3>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        1. ¿Nos cercioramos si la opinión que vamos a emitir se
                        encuentra comprendida dentro de los ‘criterios no
                        vinculativos de las disposiciones fiscales y aduaneras’?{" "}
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="nonBindingCrit"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.nonBindingCrit && touched.nonBindingCrit ? (
                          <p class="text-danger mt-2">
                            {errors.nonBindingCrit}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div class="row form-group">
                      <label
                        for="organizationLabel"
                        class="col-sm-7 col-form-label input-label"
                      >
                        2. Si el contenido de la opinión difiere de los
                        ‘criterios no vinculativos de las disposiciones fiscales
                        y aduaneras’, ¿Se le advirtió al cliente sobre la
                        existencia del criterio contrario publicado por las
                        autoridades fiscales? <b style={{ color: "red" }}>*</b>
                      </label>
                      <div class="col-sm-5">
                        <Field
                          component="select"
                          name="difNonBindingCrit"
                          class="form-control custom-select"
                          required
                        >
                          <option value="">...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="NA">NA</option>
                        </Field>
                        {errors.difNonBindingCrit &&
                          touched.difNonBindingCrit ? (
                          <p class="text-danger mt-2">
                            {errors.difNonBindingCrit}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    { /* TODO DEBUG */


                      codFiscalFederationS.value == "SIP" || (formData.codFiscalFederation == "SIP" && codFiscalFederationS.value == "") ? (
                        <div>
                          <h3 class="pb-3">
                            Esquema Reportable de conformidad
                          </h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              Pregunta 1
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="pregunta1"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="NA">NA</option>
                              </Field>
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              Pregunta 2
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="pregunta2"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="NA">NA</option>
                              </Field>
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              Pregunta 3
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="pregunta3"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="NA">NA</option>
                              </Field>
                            </div>
                          </div>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-lg mb-5">
                  <div class="card-body">
                    <h1>
                      Apruebo el contenido de la hoja de control de calidad
                    </h1>
                    <button
                      type="submit"
                      class="btn btn-lg btn-block btn-success"
                    >
                      Aprobar
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-block btn-danger"
                      onClick={onSubmitRejected}
                    >
                      Rechazar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </main>
);

QaApprovalV1.propTypes = {
  formData: PropTypes.object,
  onSubmitRejected: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default QaApprovalV1;
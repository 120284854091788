import React, { useState, useRef } from "react";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers"
import { formatFilters } from "components/reviews/ReviewList.lib.filters";
import View from "components/reviews/ReviewList.view";

function ReviewList({ filters }) {

  const userId = sessionStorage.getItem("id");
  const pageSize = 25;
  const [pageNum, setPageNum] = useState(1);

  const reqReviews = usePagination(`{
    reviewPagination {
      reviews {
        deadline
        token
        isApproved
        wasCorrected
        dateReviewed
        dateApproved
        commentsCorrection
        isDelayed
        document {
          status
          data
          title
          folio
          client
          isClosed
          isManual
          syncDate
          documentDate
        }
      }
    }
  }`, pageNum, pageSize, formatFilters(filters, userId), { orderBy: "-created_at" });

  const onClickPage = (pageNum) =>
    setPageNum(pageNum);

  if (reqReviews.loading) return <Loading />;
  if (reqReviews.error) window.location.replace("/logout");

  const reviews = reqReviews.data.reviewPagination?.reviews ? [...reqReviews.data.reviewPagination.reviews] : [];
  const { totalPages = 0 } = reqReviews.data.reviewPagination;

  if (!filters["reviewed"]) {
    reviews.sort((a, b) => {
      if (a.isDelayed && !b.isDelayed) return -1;
      if (!a.isDelayed && b.isDelayed) return 1;
      return 0;
    });
  }

  return (
    <View
      reviews={reviews}
      totalPages={totalPages}
      pageNum={pageNum}
      isReviewedShown={filters["reviewed"]}
      onClickPage={onClickPage}
    />
  );
}

ReviewList.propTypes = {};

export default ReviewList;
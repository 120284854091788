import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { useHistory, useLocation } from "react-router";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import NotFound from "components/utils/NotFound";
import View from "components/partner/Review.view";

function Review({ review, isReadOnly = false }) {

  const history = useHistory();
  const [error, setError] = useState(null);
  const [reviewed, setReviewed] = useState(false);

  const reqApprovals = useQuery(`{
    approvals { id }
  }`, `requestId=${review.document.request.id}`)

  const reqDocuments = useQuery(`{
    documents {
        omitted
        file { url }
        request { ndDocumentId }
      }
    }`, `id=${review.document.id}`)

  const [callSaveReview, reqSaveReview] = usePost("/documents/create_review", {
    onCompleted: () => {
      swal({
        title: "Revisión creada exitosamente",
        icon: "success",
        text: "La revisión ha sido creada con éxito, esta será revisada por el equipo de control de calidad",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((response) => {
        setReviewed(true);
      });
    },
    onError: (err) => {
      console.log(err);
      setError("Ocurrió un error interno, por favor intente mas tarde")
    }
  });

  if (reqApprovals.loading || reqDocuments.loading) return <Loading />;
  if (reqApprovals.error || reqDocuments.error) return <NotFound />;

  const { approvals = [] } = reqApprovals.data;
  const { documents = [] } = reqDocuments.data;

  let approvalId = 0;
  if (approvals.length > 0) {
    approvalId = approvals[0].id;
  }

  const onSubmit = (values) => {
    const hasComments = values["hasComments"]
    const comments = values["comments"]
    const needsCommittee = values["needsCommittee"]

    if (!hasComments || !needsCommittee) {
      setError("Debe llenar todos los campos")
      return;
    }

    if (hasComments == "Yes" && !comments) {
      setError("Debe llenar todos los campos")
      return;
    }

    callSaveReview({
      reviewId: review.id,
      hasComments: hasComments == "Yes",
      comments: hasComments == "Yes" ? comments : "",
      needsCommittee: needsCommittee == "Yes"
    })

  }

  const handleOnClick = (values) => {
    let cronoOmitted = documents.length > 0 && documents[0].omitted
    sessionStorage.setItem("values", JSON.stringify(values));
    if (!cronoOmitted) {
      const url = `/view/${approvalId}/${review.document.id}`;
      if (isReadOnly) {
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        history.push(url);
      }
    } else {
      const url = documents[0].file ? documents[0].file.url :
        `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${documents[0].request.ndDocumentId}`
      if (isReadOnly) {
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        window.location.href = url;
      }

    }
  }


  return (
    <View
      review={review}
      reviewed={reviewed}
      error={error}
      isReadOnly={isReadOnly}
      handleOnClick={handleOnClick}
      onSubmit={onSubmit}
    />
  );
}

Review.propTypes = {
  review: PropTypes.object.isRequired
};

export default Review;
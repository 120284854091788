import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import swal from "sweetalert";
import "moment/locale/es-mx";
import { usePost } from "seed/api";
import { useDetail, useQuery } from "seed/gql";
import { useHistory } from "react-router-dom";
import { Loading } from "seed/helpers";
import View from "components/cc/reviewer/Reviewer.view";

function ReviewerSave({onCompleted = () => null, onError = () => null, reqDocuments, documentId}) {
  const [error, setError] = useState(null);

  const reqPartners = useQuery(`{
    users {
      firstName
      lastName
      email
    }
  }`, "status='PARTNER'", {
    onError: () => setError("Ha ocurrido un error"),
    orderBy: "first_name"
  });

  const reqDocument = useDetail(`{
    document {  }
  }`, documentId)

  const [callSave, qSave] = usePost("/documents/register_partner", {
    onCompleted: () => {
      swal({
        title: "Socios asignados exitosamente",
        icon: "success",
        text: "Los socios han sido asignados exitosamente para la revisión del documento",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/cc_home";
        }
      });   
    }, 
    onError: (data) => {
      if (data.status == 401) {
        setError("Hubo un error, el documento ya tiene al menos un socio asignado");
      }  else {
        setError("Error interno del servidor, por favor intente mas tarde");
      }
    }
  });

  if (reqPartners.loading || reqDocument.loading) return <Loading />;
  if (reqPartners.error) return "Error";

  const { users = [] } = reqPartners.data;
  const document = reqDocument.data?.document;

  if (!document) window.location.href = "/cc_home";

  const onSubmit = (values) => {
    const deadline = values["deadline"];
    const reviewers = values?.reviewers??[];

    if (!deadline || reviewers.length == 0) {
      setError("Debe rellenar todos los campos")
      return; 
    } 

    const now = moment();
    const timeDeadline = moment(deadline);

    if (now > timeDeadline) {
      setError("La fecha limite debe ser mayor que la fecha actual");
      return;
    }

    callSave({
      documentId: documentId,
      deadline: deadline,
      reviewers: reviewers,
    })
  }

  return <View partners={users} onSubmit={onSubmit} error={error}/>;
}

ReviewerSave.propTypes = {
  onCompleted: PropTypes.func, 
  onError: PropTypes.func, 
  documentId: PropTypes.string, 
  reqDocuments: PropTypes.object,
  props: PropTypes.object,
  match: PropTypes.object
};

export default ReviewerSave;
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/correction/Correction.view";

function Correction({ onCompleted = () => null, onError = () => null, reqDocuments, documentId }) {

  const [error, setError] = useState(null);
  const [selectedIdxReview, setSelectedIdxReview] = useState(0);
  const [selectedReview, setSelectedReview] = useState(null);


  const [callSaveCorrection, reqSaveCorrection] = usePost("/documents/correction_admin", {
    onCompleted: () => {
      swal({
        title: "Revisión creado exitosamente",
        icon: "success",
        text: "La revisión se ha registrado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/cc_home";
        }
      });
    },
    onError: (data) => {
      console.log(data);
      if (data.status == 401) {
        setError("El documento no esta revisado, por favor reinicie la página");
      } else {
        setError("Error interno del servidor, intente mas tarde")
      }
    }
  });

  const reqDocument = useDetail(`{
    document {
      data
      createdAt
      updatedAt
      status
      request {
        ndDocumentId
      }
      file {
        url
      }
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/cc_home";
    }
  })

  const reqReviews = useQuery(`{
    reviews {
      createdAt
      updatedAt
      hasComments
      needsCommittee
      comments
      dateReviewed
      dateApproved
      reviewer {
        email
        lastName
        firstName
      }
    }
  }`, `document.id = ${documentId} AND has_comments=True`, {
    onCompleted: (data) => {
      if (data.reviews.length > 0) {
        setSelectedReview({ ...data.reviews[0] });
      }
    },
    onError: (error) => {
      console.log(error);
      window.location.href = "/not_found";
    },
    orderBy: "date_reviewed"
  })

  if (reqDocument.loading || reqReviews.loading) return <Loading />;
  if (reqDocument.error || reqReviews.error) return "Error";

  const { reviews = [] } = reqReviews.data;
  const { document = {} } = reqDocument.data;

  if (document.status != "WITH_COMMENTS")
    window.location.href = "/cc_home";

  const onSubmit = (values) => {
    const comments = values["comments"]

    if (!comments) {
      setError("Debe llenar todos los campos");
      return;
    }

    callSaveCorrection({
      documentId: documentId,
      comments: comments,
    })

  }

  const onChangeSelectedReview = (event, newValue) => {
    setSelectedIdxReview(newValue);
    setSelectedReview({ ...reviews[newValue] });
  }

  return (
    <View
      error={error}
      selectedIdxReview={selectedIdxReview}
      selectedReview={selectedReview}
      reviews={reviews}
      document={document}
      onSubmit={onSubmit}
      onChangeSelectedReview={onChangeSelectedReview}
    />
  );
}

Correction.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  reqDocuments: PropTypes.object,
  documentId: PropTypes.string,
  props: PropTypes.object,
  match: PropTypes.object
};

export default Correction;
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";

function Signers({ history }) {
  const ndDocumentId = localStorage.getItem("NdDocumentId");
  const ndEnvelopeId = localStorage.getItem("NdEnvelopeId");
  const ndVerNo = localStorage.getItem("NdVerNo");
  const ndUserId = localStorage.getItem("NdUserId");

  const [callFirm, reqFirm] = usePost("/users/" + ndUserId + "/send_to_sign", {
    onCompleted: (data) => {
      const { id } = data;
      history.replace("/request?id=" + id)
    }
  });

  useEffect(() => {
    callFirm({
      signers: JSON.stringify([
        { firstName: "", lastName: "", email: "", organization: "" },
      ]),
      documentId: ndDocumentId,
      envelopeId: ndEnvelopeId,
      verNo: ndVerNo,
      checked: "false",
    });
  }, []);

  return <div></div>;
}

Signers.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Signers;

import React from "react";
import PropTypes from "prop-types";
import View from "components/partner/ViewQaApprovalCc.view";

function ViewQaApprovalCc(props) {
  
  const { formId, documentId } = props.match.params;
  
  return <View formId={formId} documentId={documentId} />;
}

ViewQaApprovalCc.propTypes = {};

export default ViewQaApprovalCc;
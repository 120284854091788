import React, { useState, useRef } from "react";
import View from "components/reviews/CopyLinkButton.view";

function CopyLinkButton({ link }) {

  const target = useRef(null);
  const [showPopover, setShowPopover] = useState(false);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 1500);
    });
  }

  return (
    <View
      target={target}
      showPopover={showPopover}
      onCopyToClipboard={onCopyToClipboard}
    />
  );
}

CopyLinkButton.propTypes = {};

export default CopyLinkButton;
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useLocation } from "react-router";

function NdRedirect({ history }) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ndUserId = localStorage.getItem("ndUserId");
  const code = query.get("code");
  const [callVerified, reqVerified] = usePost(
    "/users/" + ndUserId + "/auth_user",
    {
      onCompleted: (data) => {
        const { verified } = data;
        if (verified) history.replace("/PageFirm");
      }
    }
  );

  useEffect(() => {
    fetch("https://app.workix.mx/debug/nd_crz_login/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: code }),
    }).finally(_ => callVerified({ code: code }));
  }, []);

  return <div></div>;
}

NdRedirect.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NdRedirect;

import React, { useState } from "react";
import { usePagination } from "seed/gql";
import PropTypes from "prop-types";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/requests/RequestList.view";

function RequestList() {
  const pageSize = 15;
  const [pageNum, setPageNum] = useState(1);
  const reqRequests = usePagination(`
  {
    requestPagination {
      totalPages
      requests {
        status
        ndDocumentId
        ndEnvelopeId
        ndVerNo
        ndUserId
        createdAt
        signedDocument { }
        createdBy { }
        signers { }
      }
    }
  }`, pageNum, pageSize);

  const reqLastApprovals = useGet("/requests/get_last_approvals");

  
  if (reqRequests.loading || reqLastApprovals.loading) return <Loading />;
  if (reqRequests.error || reqLastApprovals.error) return "Error";
  

  const { requests = [], totalPages = 0 } = reqRequests.data.requestPagination;
  const lastApprovals = reqLastApprovals.data

  const onClickPage = (pageNum) =>
    setPageNum(pageNum);

  return <View
    requests={requests}
    pageNum={pageNum}
    totalPages={totalPages}
    onClickPage={onClickPage}
    lastApprovals={lastApprovals}
  />;
}

RequestList.propTypes = {};

export default RequestList;
import React from "react";
import PropTypes from "prop-types";

const EmptyView = () => (
  <div class="d-flex justify-content-center">
    <div class="card w-50 p-5">
      {/* Header */}
      <div class="card-header">
        <h4 class="card-header-title">Documento ya revisado</h4>
      </div>

      {/* Body */}
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p>Este documento ya ha sido revisado, por favor intente con otro o contecte a equipo de control de calidad.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
);

EmptyView.propTypes = {};

export default EmptyView;
import React from "react";
import PropTypes from "prop-types";

const NowVerify = ({
  status,
  onResend,
  inputPartners,
  inputAssociated,
  stage,
  IS_PROD,
}) => (
  <main id="content" role="main" class="main">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-lg mb-5">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <img
                    class="avatar-xxl mb-3 ml-4"
                    src="/theme/svg/logos/warning.svg"
                    alt="warning"
                  />
                </div>
                {status == "SIGNED" ? (
                  <div class="col-7">
                    <h1 class="card-title">Este documento ya está aprobado.</h1>
                    <p class="card-text mt-2">
                      Dirigite a netDocuments para aprobar otro.
                    </p>
                  </div>
                ) : status == "REJECTED" ? (
                  <div class="col-7">
                    <h1 class="card-title">
                      Este documento está en proceso de correciones.
                    </h1>
                    <p class="card-text mt-2">
                      Dirigite a netDocuments y espera a que comience el flujo
                      de aprobación.
                    </p>
                  </div>
                ) : (
                  <div class="col-7">
                    <h1 class="card-title">
                      Este documento ya está en proceso de aprobación.
                    </h1>
                    <p class="card-text mt-2">
                      Completa el flujo de aprobación o dirigite a netDocuments.
                    </p>
                  </div>
                )}
              </div>
              {IS_PROD ? null : status != "SIGNED" ||
                status != "REJECTED" ? null : (
                <>
                  <hr
                    class="my-6"
                    style={{ "border-top": "1px dashed green" }}
                  />
                  <h3>Reenviar Correos</h3>
                  {inputPartners.length > 0 ? (
                    <>
                      <h5>Socios relacionados</h5>
                      {stage == "associated" ? (
                        <p>
                          {" "}
                          <b>
                            Se debe completar el flujo de asociados para poder
                            notificar a los socios.
                          </b>
                        </p>
                      ) : null}
                      {inputPartners.map((partners, index) => (
                        <p>
                          {partners.name}
                          {!partners.approval ? (
                            stage == "partners" ? (
                              <a
                                class="btn btn-link"
                                onClick={() => onResend(partners.email, stage)}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-arrow-right"></i> Reenviar
                              </a>
                            ) : null
                          ) : (
                            <b> Aprobación Completa</b>
                          )}
                        </p>
                      ))}
                    </>
                  ) : null}

                  {inputAssociated.length > 0 ? (
                    <>
                      <h5>Asociados relacionados</h5>

                      {inputAssociated.map((associated, index) => (
                        <p>
                          {associated.name}
                          {!associated.approval ? (
                            <a
                              class="btn btn-link"
                              onClick={() => onResend(associated.email, stage)}
                              style={{ color: "#007473" }}
                            >
                              <i class="fas fa-arrow-right"></i> Reenviar
                            </a>
                          ) : (
                            <b> Aprobación Completa</b>
                          )}
                        </p>
                      ))}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

NowVerify.propTypes = {};

export default NowVerify;

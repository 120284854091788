import React from "react";
import PropTypes from "prop-types";
import Overlay from 'react-bootstrap/Overlay';

const CopyLinkButtonView = ({ target, showPopover, onCopyToClipboard }) => (
  <>
    <button className="btn btn-light ml-2" ref={target} onClick={onCopyToClipboard}>
      <i class="far fa-copy"></i>
    </button>
    <Overlay target={target.current} show={showPopover} placement="bottom">
      {({
        placement: _placement,
        arrowProps: _arrowProps,
        show: _show,
        popper: _popper,
        hasDoneInitialMeasure: _hasDoneInitialMeasure,
        ...props
      }) => (
        <div
          {...props}
          style={{
            position: 'absolute',
            backgroundColor: '#f0f3f5',
            padding: '2px 10px',
            color: '#40454a',
            borderRadius: 3,
            marginTop: "10px",
            ...props.style,
          }}
        >
          Link copiado
        </div>
      )}
    </Overlay>
  </>
)

CopyLinkButtonView.propTypes = {};

export default CopyLinkButtonView;
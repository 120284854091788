import React from "react";
import moment from "moment";
import "moment/locale/es-mx";
import { Link } from "react-router-dom";
import { getStatusName, getStatusClass } from "components/utils/constants";
import { APP_URL } from "settings";
import CopyLinkButton from "components/reviews/CopyLinkButton";


const ReviewItem = ({ review, style = {} }) => (
    <tr class="justify-content-center" role="row" key={`review_${review.id}`} style={style}>
        {/* Document Folio */}
        <td>
            {review.document.folio}
        </td>

        {/* Document Name */}
        <td style={{ maxWidth: "400px", overflow: "hidden" }}>
            <div><b>{review.document.data?.title ?? ""}</b></div>
            <div><span class="badge py-0 px-0">{review.document.client ?? ""}</span></div>
            <div className="mt-0 pt-0">
                <small style={{ fontSize: "0.8em" }}>
                    {review.document.status == "SENT" ? "" : "Fecha doc: " + moment(review.document.documentDate).format("DD-MMM-YYYY").replace(".", "")}
                </small>
            </div>
        </td>

        {/* Review Status */}
        <td>
            <span class={getStatusClass(review.document.status)}
                style={(review.document.status == "REVIEWED" || review.document.status == "PARTIALLY_REVIEWED") ? { backgroundColor: "#c7f0c0" } : {}}>
                {getStatusName(review.document.status)}
            </span>

            <div className="mt-0 pt-0">
                <small style={{ fontSize: "0.9em" }}>
                    {review.dateReviewed ?
                        (<span>{"Fecha de revisión: " + moment(review?.dateReviewed).format("DD-MMM-YYYY").replace(".", "")}<br /></span>) : ""}
                </small>
                <small style={{ fontSize: "0.9em" }}>
                    {review.document.status != "SENT" ?
                        ("Fecha límite: " + moment(review.deadline).format("DD-MMM-YYYY").replace(".", "")) : ""}
                    {(review.document.status == "ASSIGNED" || review.document.status == "IN_REVIEW") && review.isDelayed ?
                        (<span class="ml-2 text-danger">(Retrasado)</span>) : null}
                </small>
            </div>
        </td>

        {/* Approval Link */}
        <td>
            {review.document.status == "IN_REVIEW" ? (
                <div className="d-flex align-items-center">
                    <a className="btn btn-primary mb-0" rel="noreferrer" target="_blank"
                        href={`${APP_URL}/review/?id=${review.id}&token=${review.token}`}
                    >
                        <i class="fas fa-link text-light mr-1"></i> Ir a la aprobación
                    </a>
                    <CopyLinkButton link={`${APP_URL}/review/?id=${review.id}&token=${review.token}`} />
                </div>
            ) : (
                <div className="d-flex align-items-center">
                    <Link to={`/${review.id}/details`} className="btn btn-primary mb-0">
                        <i class="fas fa-info-circle text-light mr-1"></i> Ver detalles
                    </Link>
                </div>
            )}
        </td>
    </tr>
)

export default ReviewItem;
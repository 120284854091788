import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Formik, Field, Form } from "formik";


function getTitle(data) {
  return (data).title;
}

const CorrectionView = ({ 
  error ,
  reviews = [], 
  selectedIdxReview,
  selectedReview,
  document = {}, 
  onSubmit = () => { }, 
  onChangeSelectedReview,
}) => (
  <div class="card">
    {/* Header */}
    <div class="card-header">
      <h4 class="card-header-title">Corrección</h4>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">

          <h4 class="pb-3">Datos de la opinión</h4>

          <div class="row mb-3">
            <div class="col-md-8">
              {getTitle(document.data)}
            </div>
            <div class="col-md-4">
              <a href={ document.file ? document.file.url : 
                `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`} 
                target="_blank" rel="noreferrer" class="btn btn-outline-primary">
                Abrir opinión
              </a>
            </div>
          </div>

          <hr
            class="my-6"
            style={{ "border-top": "1px dashed green" }}
          />
          <h4 class="mb-1">Datos de la revisión</h4>

          <Tabs
            value={selectedIdxReview}
            onChange={onChangeSelectedReview}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {reviews.map(review => (
              <Tab key={review.id} label={review?.reviewer?.firstName + " " + review?.reviewer?.lastName} />
            ))}
          </Tabs>

          <table class="table mt-3">

            <tr>
              <td>Con comentarios</td>
              <td>{selectedReview?.hasComments ? "Si" : "No"}</td>
            </tr>

            <tr>
              <td>Necesita comité</td>
              <td>{selectedReview?.needsCommittee ? "Si" : "No"}</td>
            </tr>

            {selectedReview?.hasComments ? (
              <tr>
                <td>Descripción de corrección</td>
                <td>{selectedReview?.comments}</td>
              </tr>
            ) : null}


          </table>

          <hr
            class="my-6"
            style={{ "border-top": "1px dashed green" }}
          />
          <h4 class="pb-3">Datos de la corrección</h4>

          <Formik initialValues={document} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>

                <div class="mb-3">
                  {/* Comments */}
                  <div class="form-group">
                    <label class="input-label">Comentarios</label>
                    <Field type="text" name="comments"
                      as="textarea" rows="6"
                      class="form-control" />
                  </div>
                </div>

                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary">REGISTRAR CORRECCIÓN</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>

  </div>
);

CorrectionView.propTypes = {
};

export default CorrectionView;
import React from "react";
import PropTypes from "prop-types";
import { useGet } from "seed/api";
import { DELETE_REQUEST } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/requests/RequestDetail.view";

function RequestDetail({ requestId }) {
  const reqRequest = useGet("/requests/" + requestId + "/get_approvals_doc_info");
  
  if (reqRequest.loading) return <Loading />;
  
  let request;

  if (reqRequest.error) {
    request = {
      "general" : {
        "name": "La información del documento no se encuentra disponible en este momento",
      },
    }

  } else
    request = reqRequest.data;

  return <View
    request={request}
   />;
}

RequestDetail.propTypes = {
  requestId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func,
  onError: PropTypes.func
};

export default RequestDetail;
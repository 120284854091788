import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Main from "components/cc/Main";
import { ScriptTag, Modal } from "seed/helpers";
import { SERVER_URL } from "settings"


const HomeView = ({ onClickGeneralReport, onClickDetailReport, onClickUsersReport, onClickDocumentsReport }) => (
  <div>

    <main id="content" role="main" class="mt-5">

      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>


      <div class="container content align-items-center justify-content-center" style={{ maxWidth: "1500px" }}>
        <a class="d-flex justify-content-between mb-5">
          <img
            class="z-index-2 ml-4"
            src="/theme/svg/logos/logoCrz.png"
            alt="Logo"
            style={{ width: "8rem" }}
          />
          <div className="d-flex">
            <div class="dropdown mr-2">
                <button class="btn btn-sm btn-outline-primary bg-white dropdown-toggle" 
                style={{ color: "#2080e5", paddingTop: "0.5rem", paddingBottom: '0.6rem' }}
                  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Reportes
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item mb-0" href="#" onClick={onClickDocumentsReport}>
                    Revisiones
                  </a>
                  <a class="dropdown-item mb-0" href="#" onClick={onClickGeneralReport}>
                    HC General
                  </a>
                  <a class="dropdown-item mb-0" href="#" onClick={onClickUsersReport}>
                    HC Pendientes
                  </a>
                </div>
              </div>

              <div class="dropdown mr-2">
                <button class="btn btn-sm btn-outline-primary bg-white dropdown-toggle" 
                style={{ color: "#2080e5", paddingTop: "0.5rem", paddingBottom: '0.6rem' }}
                  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Enlaces
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item mb-0" href="https://chevezruiz-my.sharepoint.com/:w:/g/personal/erick_rivas_chevez_com_mx/EdBHdkwCKEZNqVvPnBFjetEBQoOWMbN13ID3wYgxwRXp_g?e=REMzsD">
                    Bitácora técnica
                  </a>
                  <a class="dropdown-item mb-0" href="https://app.workix.mx/p/4d8a364">
                   Aplicativo de ND
                  </a>
                </div>
              </div>



            <Link class="btn btn-sm btn-outline-primary bg-white"
              style={{ color: "#2080e5" }} to="/logout">
              Cerrar Sesión
            </Link>

          </div>
        </a>
        <Main />
      </div>

    </main>

    <ScriptTag
      content={`
      // Builder toggle invoker
      $('.js-navbar-vertical-aside-toggle-invoker').click(function () {
        $('.js-navbar-vertical-aside-toggle-invoker i').tooltip('hide');
      });

      // Initialization of navbar vertical navigation
      var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

      // Initialization of tooltip in navbar vertical menu
      $('.js-nav-tooltip-link').tooltip({ boundary: 'window' })

      $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
        if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
          return false;
        }
      });

      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });

      // Initialization of form search
      $('.js-form-search').each(function () {
        new HSFormSearch($(this)).init()
      });
    `}
    />

  </div>

);

HomeView.propTypes = {
  trash: PropTypes.bool
};

export default HomeView;
import React from "react";
import QaApprovalCC from "components/partner/QaApprovalCC";
import "styles/css/HcDetails.css"


const getStatusName = (status) => {
  if (status == "WAITING_SIGN") return "📃 Esperado a ser revisado";
  if (status == "SIGNED") return "✅ Revisado y firmado";
  if (status == "ERROR") return "⚠️ Con errores";
  return "";
}

const getStatusClass = (status) => {
  if (status == "WAITING_SIGN") return "badge bg-secondary text-white";
  if (status == "SIGNED") return "badge bg-success text-white";
  if (status == "ERROR") return "badge bg-error text-white";
  return "";
}


const HcDetailsView = ({ document, approval, request }) => (
  <main
    id="content"
    style={{ "padding-left": "2.25rem" }}
  >
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <h1
          class="mt-2 display-4"
          style={{
            position: "absolute",
            "z-index": "3",
            color: "white",
            "margin-right": "22.5rem",
          }}
        >
          Hoja Crono
        </h1>
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-9">

          <div className="card">

            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center w-100 px-5 py-3">
                <h4 className="pr-5">{approval?.body?.title ?? "----"}</h4>
                <h4>
                  <span class={getStatusClass(request.status)}>
                    {getStatusName(request.status)}
                  </span>
                </h4>
              </div>

            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">

                  <div className="component-wrapper">
                    <div className="overlay"></div>
                    <div className="component-content">
                      <QaApprovalCC formId={approval.id} documentId={document.id} />
                    </div>
                  </div>

                </div>
                <div className="col-md-1"></div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </main>
)

HcDetailsView.propTypes = {};

export default HcDetailsView;
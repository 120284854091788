import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const helpMessages = [
    {
        name: "document_date",
        message: "Fecha interna en la que se manejó el documento "
    },
    {
        name: "deadline",
        message: "Fecha límite para que el socio revise el documento"
    },
    {
        name: "document_date_month",
        message: "Filtro de mes para la fecha de documento"
    },
    {
        name: "document_date_year",
        message: "Filtro de año para la fecha de documento"
    },
    {
        name: "view_document",
        message: "Botón para visualizar el documento"
    },
    {
        name: "edit_document",
        message: "Botón para editar Hoja Crono"
    },
    {
        name: "delete_document",
        message: "Botón para eliminar el documento"
    },
    {
        name: "resend_document",
        message: "Botón para reenviar el documento a los socios pendientes por revisar"
    },
    {
        name: "assign_document",
        message: "Botón para asignar socio para que revise el documento"
    },
    {
        name: "review_document",
        message: "Botón para empezar la revisión"
    },
    {
        name: "details_document",
        message: "Botón para revisar los detalles del documento"
    },
    {
        name: "restore_document",
        message: "Botón para restaurar el documento"
    },
    {
        name: "search",
        message: "Busca revisiones por folio, nombre de documento y cliente"
    }
];

const getStatusName = (status) => {
    if (status == "SENT") return "Sin asignar";
    if (status == "ASSIGNED") return "▶️ Propuesta de asignación";
    if (status == "IN_REVIEW") return "⚙️ Liberado a revisión";
    if (status == "PARTIALLY_REVIEWED") return "⚙️ Parcialmente revisado";    
    if (status == "REVIEWED") return "✅ Revisado";
    if (status == "WITH_COMMENTS") return "⚠️ Revisado con comentarios";
    if (status == "NEEDS_COMMITTEE") return "⚠️ Requiere comité";
    if (status == "REVIEWED_WITH_COMMENTS") return "📃 Respuesta a comentario";
    if (status == "REVIEWD_WITH_COMMITTEE") return "📃 Registro del comité";
}

const getStatusClass = (status) => {
    if (status == "SENT") return "badge bg-secondary text-white";
    if (status == "ASSIGNED") return "badge bg-primary text-white";
    if (status == "IN_REVIEW") return "badge bg-info text-white";
    if (status == "PARTIALLY_REVIEWED") return "badge bg-info text-white";    
    if (status == "REVIEWED") return "badge";
    if (status == "WITH_COMMENTS") return "badge bg-warning";
    if (status == "NEEDS_COMMITTEE") return "badge bg-danger text-white";
    if (status == "REVIEWED_WITH_COMMENTS") return "badge bg-light";
    if (status == "REVIEWD_WITH_COMMITTEE") return "badge bg-light";
}

const getDecisionName = (status) => {
    if (status == "REMOVE_LETTER") return "Retirar carta";
    if (status == "REPLACE") return "Sustituir";
    if (status == "COMPLEMENT") return "Complementar";
    if (status == "TALK_WITH_ISSUER") return "Platica con emisor";
    if (status == "NOTHING") return "No hacer nada";
}

const getHelpComponent = (message) => {
    return (
        <OverlayTrigger key="bottom" placement="bottom"
            overlay={
                <Popover id="popover-positioned-right">
                    <Popover.Body className="p-2"
                        style={{ backgroundColor: "#eee", color: "#000", borderRadius: "10px" }}>
                        {message}
                    </Popover.Body>
                </Popover>
            }
        >
            <i class="fas fa-info-circle ml-2" style={{color: "#bbb"}}></i>
        </OverlayTrigger>
    )
}

const getHelpMessage = (name) => {
    return helpMessages.find(item => item.name == name)?.message ?? "";
}

export { getStatusName, getStatusClass, getDecisionName, getHelpComponent, getHelpMessage };
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FORM_SCHEMA } from "utils";
import { useDetail, useQuery, useSave } from "seed/gql";
import { APPROVAL, SET_APPROVAL, USER } from "seed/gql/queries";
import View from "components/form/v1/QaApprovalV1.view";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import { useGet, usePost } from "seed/api";
import { useLocation } from "react-router";
import NotFound from "components/utils/NotFound";

function QaApprovalV1() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [callLoading, setCallLoading] = useState(false);
  const formId = localStorage.getItem("formId");
  const userId = query.get("userId");
  const reqUser = useGet("/users/" + userId);
  const reqDocument = useGet("/approvals/" + formId + "/get_document_name_id");
  const [docTypeS, setDocTypeS] = useState({ value: "", hidden: false });
  const [queryPermS, setQueryPermS] = useState({ value: "", hidden: false });
  const [formatSignS, setFormatSignS] = useState({ value: "", hidden: false });
  const [formatEnS, setFormatEnS] = useState({ value: "", hidden: false });
  const [docIsS, setDocIsS] = useState({ value: "", hidden: false });
  const [notifyReportS, setNotifyReportS] = useState({
    value: "",
    hidden: false,
  });
  const [otherDateSendS, setOtherDateSendS] = useState({
    value: "",
    hidden: false,
  });
  const [codFiscalFederationS, setCodFiscalFederationS] = useState({
    value: "",
  });

  const reqForm = useGet(
    "/approvals/" + formId,
    {},
    {
      onCompleted: (data) => {
        const { body } = data;
      }
    }
  );


  const onCompletedSet = () => {
    swal({
      title: "Información correcta",
      icon: "success",
      text: "Se aprobó el contenido",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then((respuesta) => {
      if (respuesta) {
        window.close();
      }
    });
  };

  const onCompletedRejected = () => {
    swal({
      title: "Contenido Rechazado",
      text: "Se envió el motivo de no aprobación",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then((respuesta) => {
      if (respuesta) {
        window.close();
      }
    });
  };

  const [callSet, reqSet] = usePost(
    "/approvals/" + formId + "/approval_request",
    {
      onCompleted: (data) => {
        setCallLoading(false);
        onCompletedSet();
      }
    }
  );

  const [callReject, reqReject] = usePost(
    "/approvals/" + formId + "/approval_request",
    {
      onCompleted: (data) => {
        onCompletedRejected();
      }
    }
  );

  if (reqForm.loading || reqUser.loading || callLoading || reqDocument.loading)
    return <Loading />;
  if (reqForm.error || reqUser.error) return "error";
  if (userId == "" || formId == "") return <NotFound />;
  const approval = reqForm.data;
  const user = reqUser.data;
  const { body, status, comments } = approval;
  const { documentId, name } = reqDocument.data;
  const formData = body;
  formData.dateDoc =
    formData.dateDoc != ""
      ? new Date(formData.dateDoc).toISOString().split("T")[0]
      : "";
  formData.dateSend = new Date(formData.dateSend).toISOString().split("T")[0];
  formData.otherDate =
    formData.otherDate != ""
      ? new Date(formData.otherDate).toISOString().split("T")[0]
      : "";

  const onSubmit = (values) => {
    values.queryPerm =
      queryPermS.value == "" ? values.queryPerm : queryPermS.value;
    values.documentType =
      docTypeS.value == "" ? values.documentType : docTypeS.value;
    values.formatSignCheck =
      formatSignS.value == "" ? values.formatSignCheck : formatSignS.value;
    values.formatEn = formatEnS.value == "" ? values.formatEn : formatEnS.value;
    values.notifyReport =
      notifyReportS.value == "" ? values.notifyReport : notifyReportS.value;
    values.otherDate =
      otherDateSendS.value == "" ? values.otherDate : otherDateSendS.value;
    values.docIs = docIsS.value == "" ? values.docIs : docIsS.value;
    values.codFiscalFederation = codFiscalFederationS.value == "" ? values.codFiscalFederation : codFiscalFederationS.value;
    if (formData == values) {
      setCallLoading(true);
      callSet({
        body: values,
        status: "APPROVED",
        comments: "",
        userId: userId,
      });
    } else {
      setCallLoading(true);
      callSet({
        status: "APPROVER_CORRECTED",
        body: values,
        comments: "",
        userId: userId,
      });
    }
  };

  const onSubmitRejected = () => {
    swal({
      text: "Indica el motivo del rechazo",
      content: { element: "textarea" },
      dangerMode: true,
      button: {
        text: "Enviar",
        closeModal: false,
      },
    }).then((motive) => {
      if (!motive) throw null;
      motive = document.querySelector(".swal-content__textarea").value;
      callReject({
        body: formData,
        status: "REJECTED",
        comments: motive,
        userId: userId,
      });
      setCallLoading(true);
    });
  };

  const approval_comments = approval.comments.split(":");
  const isUserRejeted = user.email == approval_comments[1] ? true : false;

  return (
    <View
      documentId={documentId}
      name={name}
      formData={formData}
      users={user}
      onSubmitRejected={onSubmitRejected}
      onSubmit={onSubmit}
      docTypeS={docTypeS}
      queryPermS={queryPermS}
      formatSignS={formatSignS}
      formatEnS={formatEnS}
      notifyReportS={notifyReportS}
      docIsS={docIsS}
      setDocIsS={setDocIsS}
      setDocTypeS={setDocTypeS}
      setQueryPermS={setQueryPermS}
      setFormatSignS={setFormatSignS}
      setFormatEnS={setFormatEnS}
      setNotifyReportS={setNotifyReportS}
      otherDateSendS={otherDateSendS}
      setOtherDateSendS={setOtherDateSendS}
      FormSchema={FORM_SCHEMA}
      status={status}
      comments={approval_comments[0]}
      isUserRejeted={isUserRejeted}
      codFiscalFederationS={codFiscalFederationS}
      setCodFiscalFederationS={setCodFiscalFederationS}
    />
  );
}

QaApprovalV1.propTypes = {};

export default QaApprovalV1;
import React from "react";
import PropTypes from "prop-types";
import View from "components/approval/Auditory.view";
import {useGet} from "seed/api";
import { Loading } from "seed/helpers";

function Auditory() {
  const reqTest = useGet("/approvals/test_metadata");
  if(reqTest.loading) return <Loading />
  console.log(reqTest.data);
  return <View info={reqTest.data}/>;
}

Auditory.propTypes = {};

export default Auditory;
import React, { useState } from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/reviews/Main.view";

const defaultFilters = {
  deadlineYear: "all",
  reviewed: false
}

function Main() {

  const userId = sessionStorage.getItem("id");
  const [filters, setFilters] = useState(
    sessionStorage.getItem("def__filters") ?
      JSON.parse(sessionStorage.getItem("def__filters")) : defaultFilters
  );

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
    }
  }`, userId)

  const onChangeFilter = (event, type) => {
    let newFilters = Object.assign({}, filters);
    if (type == "reviewed") {
      newFilters[type] = !newFilters[type]
    } else {
      newFilters[type] = event.target.value;
    }
    sessionStorage.setItem("def__filters", JSON.stringify(newFilters));
    setFilters(newFilters);
  }

  if (reqUser.loading) return <Loading />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;

  return (
    <View
      user={user}
      filters={filters}
      onChangeFilter={onChangeFilter}
    />
  );
}

Main.propTypes = {};

export default Main;